import React, { Component } from "react";
import "semantic-ui-css/semantic.min.css";

import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import "../styles/Calculator.scss";
import { Form, Button, Container } from "semantic-ui-react";
import firebase from "firebase";
import Media from "react-media";
import {
  URL,
  API_KEY,
  SUBSCRIBE_LIST_ID,
  SUBSCRIBE_TO_LIST,
  CUSTOM_FIELDS,
} from "../config";
import axios from "axios";
import ReactPixel from 'react-facebook-pixel';
// ReactPixel.init('683240455519158');
// ReactPixel.pageView(); 	

class IntroQuestion extends Component {
  state = {
    isSent: false,
  };

  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  update = (callback) => {
    const currStep = this.props.step;
    const firstName =
      this.props.values.fullName.split(" ").slice(0, -1).join(" ") || "";
    const lastName =
      this.props.values.fullName.split(" ").slice(-1).join(" ") || "";

      var now = Date();
      var nowValue = Math.floor(Date.now() / 1000);
  
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1; 
      var yyyy = today.getFullYear();
  
      if (dd < 10) {
        dd = "0" + dd;
      }
  
      if (mm < 10) {
        mm = "0" + mm;
      }
      var dateVal = yyyy.toString() + mm.toString() + dd.toString();
  


    axios
      .post(
        `${URL}contact/sync?api_key=${API_KEY}`,
        {
          contact: {
            email: this.props.values.email,
            firstName,
            lastName,
          },
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(({ data }) => {
        axios.post(
          `${URL}contactLists?api_key=${API_KEY}`,
          {
            contactList: {
              list: SUBSCRIBE_LIST_ID,
              contact: data.contact.id,
              status: SUBSCRIBE_TO_LIST,
            },
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        ).then(response => { 
          console.log(response)
        })
        .catch(error => {
          // firebase
          // .database()
          // .ref("errors")
          // .push({
          //   error:error.toString(),
          //   errorLocation:'question0',
          //   timestamp: now,
          //   timestampValue: nowValue,
          // });
          //   console.log(error.response)
        });
        //update contact
        if (
          ![undefined, null, ""].includes(this.props.values.Amount) &&
          currStep === 1 &&
          this.props.initialValue.Amount !== this.props.values.Amount
        ) {
          axios.post(
            `${URL}fieldValues?api_key=${API_KEY}`,
            {
              fieldValue: {
                contact: data.contact.id,
                field: CUSTOM_FIELDS.LOAN_AMOUNT,
                value: "$" + this.numberWithCommas(this.props.values.Amount),
              },
            },
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          ).then(response => { 
            console.log(response)
          })
          .catch(error => {
            firebase
            .database()
            .ref("errors")
            .push({
              error:error.toString(),
              errorLocation:'question1',
              timestamp: now,
              timestampValue: nowValue,
            });
              console.log(error.response)
          });
        }
        if (
          ![undefined, null, ""].includes(this.props.values.int) &&
          currStep === 2 &&
          this.props.initialValue.int !== this.props.values.int
        ) {
          axios.post(
            `${URL}fieldValues?api_key=${API_KEY}`,
            {
              fieldValue: {
                contact: data.contact.id,
                field: CUSTOM_FIELDS.INTEREST_RATE,
                value: this.props.values.int,
              },
            },
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          ).then(response => { 
            console.log(response)
          })
          .catch(error => {
            firebase
            .database()
            .ref("errors")
            .push({
              error:error.toString(),
              errorLocation:'question2',
              timestamp: now,
              timestampValue: nowValue,
            });
              console.log(error.response)
          });
        }
        if (
          ![undefined, null, ""].includes(this.props.values.Totalyears) &&
          currStep === 3 &&
          this.props.initialValue.Totalyears !== this.props.values.Totalyears
        ) {
          axios.post(
            `${URL}fieldValues?api_key=${API_KEY}`,
            {
              fieldValue: {
                contact: data.contact.id,
                field: CUSTOM_FIELDS.REMAINING_YEARS,
                value: this.props.values.Totalyears,
              },
            },
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          ).then(response => { 
            console.log(response)
          })
          .catch(error => {
            firebase
            .database()
            .ref("errors")
            .push({
              error:error.toString(),
              errorLocation:'question3',
              timestamp: now,
              timestampValue: nowValue,
            });
              console.log(error.response)
          });
        }
        if (
          ![undefined, null, ""].includes(this.props.values.mortgageTerm) &&
          currStep === 4 &&
          this.props.initialValue.mortgageTerm !==
            this.props.values.mortgageTerm
        ) {
          axios.post(
            `${URL}fieldValues?api_key=${API_KEY}`,
            {
              fieldValue: {
                contact: data.contact.id,
                field: CUSTOM_FIELDS.MORTGAGE_TERM,
                value: this.props.values.mortgageTerm,
              },
            },
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          ).then(response => { 
            console.log(response)
          })
          .catch(error => {
            firebase
            .database()
            .ref("errors")
            .push({
              error:error.toString(),
              errorLocation:'question4',
              timestamp: now,
              timestampValue: nowValue,
            });
              console.log(error.response)
          });
        }

        callback && callback();
      }).then(response => { 
        console.log(response)
      })
      .catch(error => {
        firebase
        .database()
        .ref("errors")
        .push({
          error:error.toString(),
          errorLocation:'questions',
          timestamp: now,
          timestampValue: nowValue,
        });
        console.log(error.response)
      });
  };

  setActiveSlide = (index) => {
    const { setActiveSlide } = this.props;
    this.update();
    setActiveSlide(index);
  };

  saveAndContinue = (e) => {
    e.preventDefault();
    console.log('save and continue');
    this.update();
    // console.log('save and continue');
    // this.update();
    // console.log('save and continue');
    // this.update();
    // console.log('save and continue');
    // this.update();
    // console.log('save and continue');
    // this.update();
    // console.log('save and continue');
    // this.update();
    // console.log('save and continue');
    // this.update();

    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  handleChange = (e, { value }) => this.setState({ value });

  componentWillMount = () => {
    if (this.props.step) {
      this.updateFirebase(0);
    }
  };
  componentDidMount = () => {
    
    this.updateFirebase();

    document
      .querySelector("input[name='fullName']")
      .addEventListener("keydown", (e) => {
        if (e.which === 9) {
          e.preventDefault();
          document.querySelector("input[name='email']").focus();
        }
      });
    document
      .querySelector("input[name='email']")
      .addEventListener("keydown", (e) => {
        if (e.which === 9) {
          e.preventDefault();
          document.querySelector(".btn-continue").focus();
        }
      });
    document
      .querySelector("input[name='amount']")
      .addEventListener("keydown", (e) => {
        if (e.which === 9) {
          e.preventDefault();
          document.querySelector(".btn-continue").focus();
        }
      });
    document
      .querySelector("input[name='int']")
      .addEventListener("keydown", (e) => {
        if (e.which === 9) {
          e.preventDefault();
          document.querySelector(".btn-continue").focus();
        }
      });
    document
      .querySelector("input[name='mortgage15']")
      .addEventListener("keydown", (e) => {
        if (e.which === 9) {
          e.preventDefault();
          document.querySelector(".btn-continue").focus();
        }
      });
    document
      .querySelector("input[name='mortgage30']")
      .addEventListener("keydown", (e) => {
        if (e.which === 9) {
          e.preventDefault();
          document.querySelector(".btn-continue").focus();
        }
      });
    document
      .querySelector("input[name='totalYears']")
      .addEventListener("keydown", (e) => {
        if (e.which === 9) {
          e.preventDefault();
          document.querySelector(".btn-continue").focus();
        }
      });
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.step !== prevProps.step) {
      this.updateFirebase();
    }
  };

  updateFirebase = (step = undefined) => {

    ReactPixel.init('683240455519158');
    ReactPixel.pageView(); 	
    
    var now = Date();
    var nowValue = Math.floor(Date.now() / 1000);

    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();

    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    var dateVal = yyyy.toString() + mm.toString() + dd.toString();

    var dateVal = yyyy.toString() + mm.toString() + dd.toString();

    const fireBaseStamp = {
      0: "dated/pageloads/firstquestion/" + dateVal,
      1: "dated/pageloads/secondquestion/" + dateVal,
      2: "dated/pageloads/thirdquestion/" + dateVal,
      3: "dated/pageloads/fourthquestion/" + dateVal,
      4: "dated/pageloads/fifthquestion/" + dateVal,
    };
    const fireBaseStamp2 = {
      0: "pageloads/firstquestion/",
      1: "pageloads/secondquestion/",
      2: "pageloads/thirdquestion/",
      3: "pageloads/fourthquestion/",
      4: "pageloads/fifthquestion/",
    };

    firebase
      .database()
      .ref(fireBaseStamp[step !== undefined ? step : this.props.step])
      .update({
        dateVal: dateVal,
        timestamp: now,
        timestampValue: nowValue,
        //visit: firebase.database.ServerValue.increment(1),
      });

    firebase
      .database()
      .ref(fireBaseStamp2[step !== undefined ? step : this.props.step])
      .push({
        dateVal: dateVal,
        timestamp: now,
        timestampValue: nowValue,
      });
  };

  validateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }

    return false;
  };

  render() {
    const { values, step, disableFirstQuestion } = this.props;

    return (
      <>
        <Container
          className="outerwrapper"
          style={{ width: "80%", minHeight: "100vh", textAlign: "center" }}
        >
          <h1 style={{ textAlign: "center", marginBottom: 10 }}>
            Bi-Weekly Mortgage Savings
          </h1>
          <p
            style={{
              textAlign: "center",
              fontSize: 20,
              width: "80%",
              margin: "auto",
              marginTop: "20px",
            }}
          >
            Discover how many years you can shave off your mortgage by paying
            bi-weekly instead of monthly.
          </p>
          <div className="ui five unstackable steps">
            {!disableFirstQuestion && (
              <a
                href="javascript:;"
                onClick={() =>
                  values.fullName &&
                  this.validateEmail(values.email) &&
                  step !== 4 &&
                  this.setActiveSlide(0)
                }
                className={`step ${step === 0 && "active"}  ${
                  (!!!(values.fullName && this.validateEmail(values.email)) ||
                    step === 0) &&
                  "disabled"
                }`}
              >
                <i className="address book outline icon"></i>
                <div className="content">
                  <span className={`title`}>Contact</span>
                </div>
              </a>
            )}
            <a
              href="javascript:;"
              onClick={() =>
                values.fullName &&
                this.validateEmail(values.email) &&
                step !== 1 &&
                this.setActiveSlide(1)
              }
              className={`step ${step === 1 && "active"}  ${
                (!!!(values.fullName && this.validateEmail(values.email)) ||
                  step === 1) &&
                "disabled"
              }`}
            >
              <i className="dollar icon"></i>
              <div className="content">
                <span className={`title`}>Amount</span>
              </div>
            </a>
            <a
              href="javascript:;"
              onClick={() =>
                values.fullName &&
                this.validateEmail(values.email) &&
                step !== 2 &&
                this.setActiveSlide(2)
              }
              className={`step ${step === 2 && "active"}  ${
                (!!!(values.fullName && this.validateEmail(values.email)) ||
                  step === 2) &&
                "disabled"
              }`}
            >
              <i className="chart line icon"></i>
              <div className="content">
                <span className={`title`}>Interest rate</span>
              </div>
            </a>
            <a
              href="javascript:;"
              onClick={() =>
                values.fullName &&
                this.validateEmail(values.email) &&
                step !== 3 &&
                this.setActiveSlide(3)
              }
              className={`step ${step === 3 && "active"}  ${
                (!!!(values.fullName && this.validateEmail(values.email)) ||
                  step === 3) &&
                "disabled"
              }`}
            >
              <i className="calendar check outline icon"></i>
              <div className="content">
                <span className={`title`}>Mortgage term</span>
              </div>
            </a>
            <a
              href="javascript:;"
              onClick={() =>
                values.fullName &&
                this.validateEmail(values.email) &&
                step !== 4 &&
                this.setActiveSlide(4)
              }
              className={`step ${step === 4 && "active"}  ${
                (!!!(values.fullName && this.validateEmail(values.email)) ||
                  step === 4) &&
                "disabled"
              }`}
            >
              <i className="calendar alternate outline icon"></i>
              <div className="content">
                <span className={`title`}>Mortgage year</span>
              </div>
            </a>
          </div>

          <Form className="form-box">
            <CarouselProvider
              currentSlide={step}
              isIntrinsicHeight={true}
              totalSlides={5}
              dragEnabled={false}
              touchEnabled={false}
              disableKeyboard
            >
              <Slider>
                <Slide index={0}>
                  <h1 className="ui centered" style={{ marginBottom: "30px" }}>
                    What is your email address?
                  </h1>
                  <Form.Field className="ac-form">
                    <input
                      className="input"
                      placeholder="Full name"
                      value={values.fullName}
                      onChange={this.props.handleChange("fullName")}
                      name="fullName"
                    />
                  </Form.Field>
                  <Form.Field>
                    <input
                      className="input"
                      placeholder="johndoe@example.com"
                      type="email"
                      value={values.email}
                      onChange={this.props.handleChange("email")}
                      name="email"
                    />
                  </Form.Field>
                </Slide>
                <Slide index={1}>
                  <h1 className="ui centered" style={{ marginBottom: "30px" }}>
                    What is the total amount you owe on your home?
                  </h1>
                  <Form.Field>
                    <input
                      pattern="[0-9]*"
                      inputMode="decimal"
                      className="input"
                      placeholder="500000"
                      value={values.Amount}
                      onChange={this.props.handleChange("Amount")}
                      name="amount"
                    />
                  </Form.Field>
                </Slide>
                <Slide index={2}>
                  <h1 className="ui centered" style={{ marginBottom: "30px" }}>
                    What is your approximate Interest Rate?
                  </h1>
                  <Form.Field>
                    <input
                      pattern="[0-9]*"
                      inputMode="decimal"
                      placeholder="3.75"
                      className="input"
                      onChange={this.props.handleChange("int")}
                      value={values.int}
                      name="int"
                    />
                  </Form.Field>
                </Slide>
                <Slide index={3}>
                  <h1 className="ui centered" style={{ marginBottom: "30px" }}>
                    What is you mortgage term (years)?
                  </h1>
                  <Form.Field>
                    <div
                      className="pre-form-mortgage-term"
                      style={{
                        display: "flex",
                        justifyContent: "row",
                        margin: 5,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <input
                        type="button"
                        value="15"
                        className={`ui button ${
                          parseInt(values.mortgageTerm) === 15 && "selected"
                        }`}
                        onClick={(e) => {
                          this.props.handleChange("mortgageTerm")(e);
                          this.props.handleChange("Totalyears")(e);
                        }}
                        name="mortgage15"
                      />
                      <input
                        type="button"
                        value="30"
                        className={`ui button  ${
                          parseInt(values.mortgageTerm) === 30 && "selected"
                        }`}
                        onClick={(e) => {
                          this.props.handleChange("mortgageTerm")(e);
                          this.props.handleChange("Totalyears")(e);
                        }}
                        name="mortgage30"
                      />
                    </div>
                  </Form.Field>
                </Slide>
                <Slide index={4}>
                  <Form.Field>
                    <h1 className="ui centered" style={{ marginBottom: 30 }}>
                      How many years are left on your mortgage?
                    </h1>
                    <input
                      pattern="[0-9]*"
                      inputMode="decimal"
                      placeholder="30 Years"
                      className="input"
                      value={values.Totalyears}
                      onChange={this.props.handleChange("Totalyears")}
                      name="totalYears"
                    />
                    {(parseInt(values.Totalyears) < 0 ||
                      parseInt(values.Totalyears) >
                        parseInt(values.mortgageTerm)) && (
                      <span style={{ color: "#7B134C" }}>
                        Mortgage must be from range 0 to {values.mortgageTerm}
                      </span>
                    )}
                  </Form.Field>
                </Slide>
              </Slider>
              <div className="footer">
                {step !== (disableFirstQuestion ? 1 : 0) && (
                  <Media
                    query="(min-width: 469px)"
                    render={() => (
                      <ButtonBack
                        className="ui button"
                        style={{
                          padding: "10px 20px",
                          height: "50px",
                          width: "120px",
                          backgroundColor: "var(--button-back-background)",
                          color: "var(--button-back-text)",
                          fontSize: "18px",
                          borderRadius: "3px",
                          marginTop: "20px",
                        }}
                        onClick={this.back}
                      >
                        Back
                      </ButtonBack>
                    )}
                  />
                )}

                <ButtonNext
                  className="ui button btn-continue"
                  style={{
                    padding: "10px 20px",
                    height: "50px",
                    width: "120px",
                    backgroundColor: "var(--button-continue-background)",
                    color: "var(--button-continue-text)",
                    fontSize: "18px",
                    borderRadius: "3px",
                    marginTop: "20px",
                  }}
                  onClick={this.saveAndContinue}
                  disabled={
                    (step === 0 &&
                      (!this.validateEmail(values.email) ||
                        !values.fullName)) ||
                    (step === 4 &&
                      (parseInt(values.Totalyears) < 0 ||
                        parseInt(values.Totalyears) >
                          parseInt(values.mortgageTerm)))
                  }
                >
                  Continue
                </ButtonNext>
              </div>
            </CarouselProvider>
          </Form>
        </Container>
      </>
    );
  }
}

export default IntroQuestion;
