import React, { Component } from "react";
import axios from "axios";
import "semantic-ui-css/semantic.min.css";
import { Form, Button, TextArea, Container } from "semantic-ui-react";

import firebase from "firebase";
import { Redirect } from "react-router-dom";
import { URL, API_KEY, SUBSCRIBE_LIST_ID, SUBSCRIBE_TO_LIST } from "../config";
import "../styles/Calculator.scss";
import moment from "moment";
import {
  BarChart,
  AreaChart,
  Area,
  linearGradient,
  Bar,
  Label,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Text,
  FunnelChart,
  Funnel,
  LabelList,
  PieChart,
  Pie,
  LineChart,
  Legend,
  Tooltip,
  Sector,
  Line,
} from "recharts";
import DatePicker from "react-datepicker";
import "react-dates/initialize";
import {
  DateRangePicker,
  SingleDatePicker,
  DayPickerRangeController,
} from "react-dates";
import "react-dates/lib/css/_datepicker.css";

import "react-datepicker/dist/react-datepicker.css";

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
const COLORS = ["#0088FE", "#00C49F"];
const RADIAN = Math.PI / 180;

class Analytics extends Component {
  state = {
    email: "",
    success: "",
    error: "",
    name: "",
    startDate: moment(),
    endDate: moment(),
    focusedInput: null,
    blog: 0,
    firstquestion: 0,
    secondquestion: 0,
    thirdquestion: 0,
    fourthquestion: 0,
    fifthquestion: 0,
    calculator: 0,
    registered: 0,
    totalMortgageInput: 0,
    avgMortgageAmount: 0,
    avgYearLeft: 0,
    avgInterestRate: 0,
  };

  componentWillMount = () => {
    this.updateStates();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.startDate &&
      prevState.endDate &&
      (prevState.startDate.toString() !== this.state.startDate.toString() ||
        prevState.endDate.toString() !== this.state.endDate.toString())
    ) {
      this.updateStates();
    }
  };

  updateStates = () => {
    const fireBaseStamp = [
      "blog",
      "calculator",
      "registered",
      "firstquestion",
      "secondquestion",
      "thirdquestion",
      "fourthquestion",
      "fifthquestion",
    ];

    fireBaseStamp.forEach((key) => {
      firebase
        .database()
        .ref(`pageloads/${key}/`)
        .orderByChild("dateVal")
        .startAt(
          this.state.startDate ? this.state.startDate.format("YYYYMMDD") : null
        )
        .endAt(
          this.state.endDate ? this.state.endDate.format("YYYYMMDD") : null
        )
        .once("value", (dataSnapshot) => {
          let count = 0;
          dataSnapshot.forEach(() => {
            count += 1;
          });
          this.setState({ [key]: count });

          if (key === "registered") {
            let data = {};
            dataSnapshot &&
              dataSnapshot.forEach((ss) => {
                ss.forEach((s) => {
                  data[s.key] = data[s.key]
                    ? Number(data[s.key]) + Number(s.val())
                    : Number(s.val());
                });
              });

            this.setState({
              totalMortgageInput: data.loanAmount || 0,
              avgMortgageAmount: data.loanAmount ? data.loanAmount / count : 0,
              avgYearLeft: data.remainingYears
                ? data.remainingYears / count
                : 0,
              avgInterestRate: data.interestRate
                ? Number(data.interestRate) / count
                : 0,
            });
          }
        });
    });
  };
  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  handleNameChange = (event) => {
    //console.log('this.state.email ' + this.state.email);

    this.setState({ name: event.target.value });
  };

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  convertToPercentage = (val) =>
    val ? (val / this.state.firstquestion) * 100 : 0;

  render() {
    const {
      blog,
      firstquestion,
      secondquestion,
      thirdquestion,
      fourthquestion,
      fifthquestion,
      calculator,
      registered,
    } = this.state;
    const data = [
      {
        // value:
        //   firstquestion >= blog ? 100 : ((blog - firstquestion) / blog) * 100,
        value: blog,
        name: "ARTICLE",
        fill: "#8884d8",
      },
      {
        value: firstquestion,
        name: "Q. 1",
        fill: "#83a6ed",
      },
      {
        value: secondquestion,
        name: "Q. 2",
        fill: "#8dd1e1",
      },
      {
        value: thirdquestion,
        name: "Q. 3",
        fill: "#82ca9d",
      },
      {
        value: fourthquestion,
        name: "Q. 4",
        fill: "#8884d8",
      },
      {
        value: fifthquestion,
        name: "Q. 5",
        fill: "#8884d8",
      },
      {
        value: calculator,
        name: "CALCULATOR",
        fill: "#83a6ed",
      },
      {
        value: registered,
        name: "REGISTERED",
        fill: "#83a6ed",
      },
    ];

    const data01 = [
      { name: "Exited", value: firstquestion - registered },
      { name: "Registered", value: registered },
    ];

    return (
      <>
        <Container
          className="analytics-container"
          style={{
            width: "80%",
            height: "1000px",
            background: "rgba(0,100,175,.0)",
            padding: "30px",
            margin: "30px",
            color: "#333",
            //textAlign: 'right'
            //margin: "0px",
            //paddingBottom: "200px",
          }}
        >
          <div
            //className="dashboard-container"
            style={{
              marginLeft: "20px",
              width: "400px",
              height: "100px",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <DateRangePicker
              minimumNights={0}
              startDate={this.state.startDate} // momentPropTypes.momentObj or null,
              startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
              endDate={this.state.endDate} // momentPropTypes.momentObj or null,
              endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
              onDatesChange={({ startDate, endDate }) =>
                this.setState({ startDate, endDate })
              } // PropTypes.func.isRequired,
              focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              isOutsideRange={() => false}
              onFocusChange={(focusedInput) => this.setState({ focusedInput })} // PropTypes.func.isRequired,
            />
          </div>

          <div style={{ display: "inline-block", margin: "20px" }}>
            <div
              className="dashboard-container"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",

                width: "400px",
                height: "500px",
                backgroundColor: "#fff",
                marginTop: "20px",
                borderRadius: "30px",
                borderStyle: "solid",
                borderWidth: "0px",
                borderColor: "#eee",
                //display:"inline-block"
                //textAlign: "center",
              }}
            >
              <h1
                style={{ marginTop: "20px", color: "black", fontSize: "20px" }}
              >
                Click-Through Analysis
              </h1>

              {blog > 0 && (
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    className="funnel-label-container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginRight: 20,
                    }}
                  >
                    {data.map(({ name, value, fill }) => (
                      <p
                        className="funnel-label"
                        style={{
                          height: document.querySelector(
                            ".recharts-layer .recharts-funnel-trapezoid"
                          )
                            ? document
                                .querySelector(
                                  ".recharts-layer .recharts-funnel-trapezoid"
                                )
                                .getBBox().height
                            : 40,
                          opacity: document.querySelector(
                            ".recharts-layer .recharts-funnel-trapezoid"
                          )
                            ? document
                                .querySelector(
                                  ".recharts-layer .recharts-funnel-trapezoid"
                                )
                                .getBBox().height
                            : 40,
                        }}
                      >
                        <span style={{ color: fill }}>
                          {name} : {value}
                        </span>
                      </p>
                    ))}
                  </div>
                  <div className="funnel">
                    <FunnelChart width={150} height={350}>
                      <Tooltip />
                      <Funnel
                        dataKey="value"
                        nameKey="name"
                        data={data}
                        // isAnimationActive={true}
                      ></Funnel>
                    </FunnelChart>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div style={{ display: "inline-block", margin: "20px" }}>
            <div
              className="dashboard-container"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                width: "400px",
                height: "500px",
                backgroundColor: "#fff",
                marginTop: "20px",
                borderRadius: "30px",
                borderStyle: "solid",
                borderWidth: "0px",
                borderColor: "#eee",

                //textAlign: "center",
              }}
            >
              <h1
                style={{ marginTop: "20px", color: "black", fontSize: "20px" }}
              >
                Registration Success
              </h1>

              <PieChart width={400} height={400}>
                <Tooltip />
                <Legend
                  formatter={(value) =>
                    ({
                      Registered:
                        "Registered " +
                        (
                          (Number(registered) /
                            Number(firstquestion || registered)) *
                            100 || 0
                        ).toFixed(0) +
                        " %",
                      Exited:
                        "Exited " +
                        (firstquestion
                          ? ((Number(firstquestion) - Number(registered)) /
                              Number(firstquestion)) *
                              100 || 0
                          : 0
                        ).toFixed(0) +
                        " %",
                    }[value])
                  }
                />
                <Pie
                  data={data01}
                  cx={200}
                  cy={200}
                  labelLine={false}
                  //label={renderCustomizedLabel}
                  outerRadius={120}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
          </div>

          <div style={{ display: "inline-block", margin: "20px" }}>
            <div
              className="dashboard-container"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                width: "400px",
                height: "500px",
                backgroundColor: "#fff",
                marginTop: "20px",
                borderRadius: "30px",
                borderStyle: "solid",
                borderWidth: "0px",
                borderColor: "#eee",

                //textAlign: "center",
              }}
            >
              <h1
                style={{
                  marginTop: "20px",
                  marginBottom: "100px",
                  color: "black",
                  fontSize: "20px",
                }}
              >
                Calculator Metrics
              </h1>

              <div
                style={{
                  marginBottom: "20px",
                  height: "40px",
                  width: "350px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  borderRadius: "3px",
                  alignItems: "center",
                  borderWidth: "0px",
                  borderBottomWidth: "3px",
                  borderStyle: "solid",
                  borderColor: "#eee",
                }}
                className="analytics-metric"
              >
                <p
                  style={{
                    marginLeft: "0px",
                    marginTop: "10px",
                    alignSelf: "center",
                    fontSize: "15px",
                    color: "#004baf",
                  }}
                >
                  Total Mortgage Input
                </p>
                <p
                  style={{
                    marginRight: "0px",
                    marginTop: "10px",
                    fontSize: "15px",
                  }}
                >
                  ${this.numberWithCommas(this.state.totalMortgageInput)}
                </p>
              </div>

              <div
                style={{
                  marginBottom: "20px",
                  height: "40px",
                  width: "350px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  borderRadius: "3px",
                  alignItems: "center",
                  borderWidth: "0px",
                  borderBottomWidth: "3px",
                  borderStyle: "solid",
                  borderColor: "#eee",
                }}
                className="analytics-metric"
              >
                <p
                  style={{
                    marginLeft: "0px",
                    marginTop: "10px",
                    alignSelf: "center",
                    fontSize: "15px",
                    color: "#004baf",
                  }}
                >
                  Avg. Mortgage Amount
                </p>
                <p
                  style={{
                    marginRight: "0px",
                    marginTop: "10px",
                    fontSize: "15px",
                  }}
                >
                  $
                  {this.numberWithCommas(
                    this.state.avgMortgageAmount.toFixed(0)
                  )}
                </p>
              </div>

              <div
                style={{
                  marginBottom: "20px",
                  height: "40px",
                  width: "350px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  borderRadius: "3px",
                  alignItems: "center",
                  borderWidth: "0px",
                  borderBottomWidth: "3px",
                  borderStyle: "solid",
                  borderColor: "#eee",
                }}
                className="analytics-metric"
              >
                <p
                  style={{
                    marginLeft: "0px",
                    marginTop: "10px",
                    alignSelf: "center",
                    fontSize: "15px",
                    color: "#004baf",
                  }}
                >
                  Avg. Years Left on Mortgage
                </p>
                <p
                  style={{
                    marginRight: "0px",
                    marginTop: "10px",
                    fontSize: "15px",
                  }}
                >
                  {this.state.avgYearLeft.toFixed(1)}
                </p>
              </div>

              <div
                style={{
                  marginBottom: "20px",
                  height: "40px",
                  width: "350px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  borderRadius: "3px",
                  alignItems: "center",
                  borderWidth: "0px",
                  borderBottomWidth: "3px",
                  borderStyle: "solid",
                  borderColor: "#eee",
                }}
                className="analytics-metric"
              >
                <p
                  style={{
                    marginLeft: "0px",
                    marginTop: "10px",
                    alignSelf: "center",
                    fontSize: "15px",
                    color: "#004baf",
                  }}
                >
                  Avg. Interest Rate
                </p>
                <p
                  style={{
                    marginRight: "0px",
                    marginTop: "10px",
                    fontSize: "15px",
                  }}
                >
                  {Number(this.state.avgInterestRate).toFixed(2)}%
                </p>
              </div>
            </div>
          </div>
        </Container>
      </>
    );
  }
}

export default Analytics;
