import React, { Component } from "react";
import axios from "axios";
import debounce from "lodash/debounce";
//import InputRange from "react-input-range";
import Display from "./Display";
import { Form, Button, Container, Checkbox } from "semantic-ui-react";
import { URL, API_KEY, CUSTOM_FIELDS } from "../config";

import "../styles/Calculator.scss";
import {
  BarChart,
  AreaChart,
  Area,
  linearGradient,
  Bar,
  Label,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Text,
  LineChart,
  Line,
} from "recharts";

import firebase from "firebase";
import Media from "react-media";
import moment from "moment";
import ReactPixel from 'react-facebook-pixel';
// ReactPixel.init('683240455519158');
// ReactPixel.pageView(); 
// const data = [
//   {
//     name: 'Total Interest Paid', "Monthly": 1117, "Bi-Weekly": 1075,
//   },

// ];
const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const CustomBarTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className="biweekly-tooltip">
        <p className="standard">
          <strong style={{ color: "black" }}>Total Standard Interest: </strong>
          <strong style={{ color: "var(--graph-text-standard)" }}>
            ${numberWithCommas(payload[0].payload.standard)}
          </strong>
        </p>
        <p>
          <strong style={{ color: "black" }}>Biweekly Interest: </strong>
          <strong style={{ color: "var(--graph-text-biweekly)" }}>
            ${numberWithCommas(payload[0].payload.biweekly)}
          </strong>
        </p>
      </div>
    );
  }
  return null;
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className="biweekly-tooltip">
        <p className="tooltip-title standard-tooltip">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <strong
              style={{
                color: "gray",
                marginBottom: 5,
                textAlign: "right",
                fontSize: 10,
              }}
            >
              Est. {payload[0].payload.name}
            </strong>
            <strong style={{ color: "black" }}>
              Standard balance remaining
            </strong>
          </div>
        </p>
        <hr />
        <p className="standard">
          {/* <strong style={{ color: "black" }}>Principal: </strong> */}
          {/* <strong style={{ color: "gray" }}>{payload[0].payload.name} - </strong> */}
          <strong style={{ color: "var(--graph-text-standard)" }}>
            $
            {numberWithCommas(
              payload[0].payload.Standard +
                (payload[0].payload.totalInterestPaidStandard -
                  payload[0].payload.Interest)
            )}
          </strong>
        </p>

        {/* <p className="standard">
          <strong style={{ color: "black" }}>Interest: </strong>
          <strong style={{ color: "gray" }}>
            $
            {numberWithCommas(
              payload[0].payload.totalInterestPaidStandard - payload[0].payload.Interest
            )}
          </strong>
        </p> */}

        <p className="tooltip-title">
          <strong style={{ color: "black" }}>Biweekly balance remaining</strong>
        </p>
        <hr />
        <p>
          {/* <strong style={{ color: "gray" }}>{payload[0].payload.name} - </strong> */}
          <strong style={{ color: "var(--graph-text-biweekly)" }}>
            $
            {numberWithCommas(
              payload[0].payload.Biweekly +
                (payload[0].payload.totalInterestPaidBiweekly -
                  payload[0].payload.InterestBiweekly)
            )}
          </strong>
        </p>
        {/* <p>
          <strong style={{ color: "black" }}>Interest: </strong>
          <strong style={{ color: "var(--maroon)" }}>
            $
            {numberWithCommas(
              payload[0].payload.totalInterestPaidBiweekly - payload[0].payload.InterestBiweekly
            )}
          </strong>
        </p> */}
      </div>
    );
  }

  return null;
};

class Calculator extends Component {
  state = {
    email: "",
    firstName: "",
    lastName: "",
    amountValue: 0,
    addamountValue: 50.0,
    yearsValue: 0,
    damountValue: 0,
    interestrate: 0,
    paymentsperyear: 12,
    yearsValueBiWeekly: 0,
    interestPaidStandard: 0,
    interestPaidBiWeekly: 0,
    monthlyPayment: 0,
    biWeeklyPayment: 0,
    biweeklyYears: 0,
    standardYears: 0,
    standardMonths: 0,
    biweeklyMonths: 0,
    principalAlreadyPaid: 0,
    interestTotalStandard: 0,
    interestTotalBiweekly: 0,
    yearsTotalStandard: 0,
    yearsTotalBiweekly: 0,
    interestSavings: 0,
    yearsSavings: 0,
    monthsAlreadyPaid: 0,
    yearsSavingsYearLater: 0,
    interestSavingsYearLater: 0,
    totalCostLoanStandard: 0,
    totalCostLoanBiweeekly: 0,
    totalInterestPaidStandard: 0,
    totalInterestPaidBiweekly: 0,
    data: [{ name: "Standard Interest Paid", Standard: 1117 }],
    dataSaved: [{ name: "Interest Savings", Savings: 1075 }],
    principalPaidAreaDataStandard: [],
    principalPaidAreaDataBiweekly: [],
    principalPaidAreaDataBiweeklyYearLater: [],
    principalPaidEarlyAreaData: [],
    termValue: 30,
    biWeeklyDimension: {
      width: 0,
      height: 0,
    },
    biWeeklyTable: {
      width: 0,
      height: 0,
    },
    isSticky: false,
    isLoading: false,
  };

  handleAmountChange = (event) => {
    this.setState({ amountValue: parseInt(event.target.value) });
    this.calculatePayment(
      parseInt(event.target.value) || 0,
      this.state.addamountValue,
      this.state.interestrate,
      this.state.yearsValue,
      this.state.termValue
    );
  };
  handleAmountBlur = (event) => {
    this.setState({ amountValue: parseInt(event.target.value || 0) });
    this.calculatePayment(
      parseInt(event.target.value || 0),
      this.state.addamountValue,
      this.state.interestrate,
      this.state.yearsValue,
      this.state.termValue
    );
  };

  handleAddAmountChange = (event) => {
    this.setState({ addamountValue: parseInt(event.target.value) });
    //this.setState({ amountValue: parseInt(this.state.amountValue)+ parseInt(this.state.addamountValue) });
    this.calculatePayment(
      this.state.amountValue,
      parseInt(event.target.value || 0),
      this.state.interestrate,
      this.state.yearsValue,
      this.state.termValue
    );
  };

  handleAddAmountBlur = (event) => {
    this.setState({ addamountValue: parseInt(event.target.value || 0) });
    //this.setState({ amountValue: parseInt(this.state.amountValue)+ parseInt(this.state.addamountValue) });
    this.calculatePayment(
      this.state.amountValue,
      parseInt(event.target.value || 0),
      this.state.interestrate,
      this.state.yearsValue,
      this.state.termValue
    );
  };

  handleTermChange = (event, { value }) => {
    if (parseInt(value)) {
      this.setState({ termValue: value });
      this.calculatePayment(
        this.state.amountValue,
        this.state.addamountValue,
        this.state.interestrate,
        this.state.yearsValue,
        parseInt(value)
      );
    }
  };

  setMortgageTerm(value) {
    if (parseInt(value)) {
      this.setState({ termValue: value });
      this.calculatePayment(
        this.state.amountValue,
        this.state.addamountValue,
        this.state.interestrate,
        this.state.yearsValue,
        parseInt(value)
      );
    }
  }

  handleYearChange = (event) => {
    if (parseInt(event.target.value) < 100) {
      this.setState({ yearsValue: parseInt(event.target.value) });
      this.calculatePayment(
        this.state.amountValue,
        this.state.addamountValue,
        this.state.interestrate,
        parseInt(event.target.value || 0),
        this.state.termValue
      );
    }
  };

  handleYearBlur = (event) => {
    if (parseInt(event.target.value) < 100) {
      this.setState({ yearsValue: parseInt(event.target.value || 0) });
      this.calculatePayment(
        this.state.amountValue,
        this.state.addamountValue,
        this.state.interestrate,
        parseInt(event.target.value || 0),
        this.state.termValue
      );
    }
  };

  handleinterestChange = (event) => {
    this.setState({ interestrate: event.target.value });
    this.calculatePayment(
      this.state.amountValue,
      this.state.addamountValue,
      event.target.value || 0,
      this.state.yearsValue,
      this.state.termValue
    );
  };

  handleinterestBlur = (event) => {
    this.setState({ interestrate: event.target.value || 0 });
    this.calculatePayment(
      this.state.amountValue,
      this.state.addamountValue,
      event.target.value || 0,
      this.state.yearsValue,
      this.state.termValue
    );
  };

  componentDidMount() {
    ReactPixel.init('683240455519158');
    ReactPixel.pageView(); 
    
    var now = Date();
    var nowValue = Math.floor(Date.now() / 1000);

    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();

    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    var dateVal = yyyy.toString() + mm.toString() + dd.toString();
    firebase
      .database()
      .ref("dated/pageloads/calculator/" + dateVal)
      .update({
        dateVal: dateVal,
        timestamp: now,
        timestampValue: nowValue,
        //visit: firebase.database.ServerValue.increment(1),
      });

    firebase.database().ref("pageloads/calculator/").push({
      dateVal: dateVal,
      timestamp: now,
      timestampValue: nowValue,
    });

    var amount = 0;
    var years = 0;
    var interest = 0;
    const {
      values: { Amount, Totalyears, mortgageTerm, int, email, fullName },
    } = this.props;

    console.log("props " + JSON.stringify(this.props));
    const firstName = fullName.split(" ").slice(0, -1).join(" ") || "";
    const lastName = fullName.split(" ").slice(-1).join(" ") || "";

    this.setState({
      email,
      firstName,
      lastName,
    });
    this.setMortgageTerm(parseInt(mortgageTerm));

    if (Amount != "") {
      amount = parseFloat(Amount);
      this.setState({ amountValue: Amount });
      //this.setState({amountValue: parseFloat(this.props.Amount) })
    } else {
      this.setState({ amountValue: 500000 });
      amount = 500000;
    }

    if (Totalyears != "") {
      this.setState({ yearsValue: Totalyears });
      years = parseFloat(Totalyears);
      //this.setState({interestrate: parseFloat(this.props.int) })
    } else {
      years = parseFloat(30);
      this.setState({ yearsValue: 30 });
    }

    if (int != "") {
      this.setState({ interestrate: int });
      interest = parseFloat(int);
      //this.setState({yearsValue: parseFloat(this.props.Totalyears) })
    } else {
      this.setState({ interestrate: 3.75 });
      interest = parseFloat(3.75);
    }
    console.log(
      "amountValue " + amount + " interestrate " + interest + " years " + years
    );

    this.calculatePayment(
      amount,
      this.state.addamountValue,
      interest,
      years,
      this.state.termValue
    );

    this.getDimensions();
    window.addEventListener("resize", this.getDimensions);
    window.addEventListener(
      "scroll",
      // debounce(() => {
      () => {
        const offset = -50;
        const offset2 = -100;
        if (
          (window.scrollY >=
            document.querySelector("#App").offsetTop + offset) &
          (window.scrollY < document.querySelector(".letter").offsetTop) &
          (this.state.isSticky === false)
        ) {
          this.setState({ isSticky: true });
        } else if (
          (window.scrollY < document.querySelector("#App").offsetTop + offset) &
          (this.state.isSticky === true)
        ) {
          this.setState({ isSticky: false });
        } else if (
          (window.scrollY >= document.querySelector(".letter").offsetTop) &
          (this.state.isSticky === true)
        ) {
          this.setState({ isSticky: false });
        }
      }
      // }, 150)
    );
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.getDimensions);
    window.removeEventListener("scroll");
  };

  getDimensions = () => {
    if (this.biWeeklyDimensionRef) {
      this.setState({
        biWeeklyDimension: {
          width:
            window.innerWidth > 1000
              ? this.biWeeklyDimensionRef.offsetWidth * 0.48
              : "100%",
          height: this.biWeeklyDimensionRef.offsetHeight * 0.48,
        },
      });
    }

    if (this.biWeeklyTableRef) {
      this.setState({
        biWeeklyTable: {
          width: this.biWeeklyTableRef.offsetWidth,
          height: this.biWeeklyTableRef.offsetHeight,
        },
      });
    }
  };

  calculatePayment(amountValue, addamountValue, interestrate, years, term) {
    var yearLater = years;
    this.calculateYearLater(
      parseInt(amountValue),
      parseInt(addamountValue),
      interestrate,
      parseInt(yearLater),
      parseInt(term)
    );
    if (addamountValue == "" || addamountValue == 0) {
      var principal = parseInt(amountValue);
    } else {
      var principal = parseInt(amountValue); //+ parseInt(addamountValue);
    } //principle / initial amount borrowed
    var interest = interestrate / 100 / 12; //monthly interest rate
    var yearlyinterest = interestrate / 100; //year interest rate
    //var paymentNumberStandard = years * 12; //number of payments months
    var paymentNumberStandard = term * 12; //number of payments months
    //var paymentNumberBiWeekly = years * 26; //number of biweekly payments months

    //monthly mortgage payment
    var mortgageStandard =
      (principal * interest * Math.pow(1 + interest, paymentNumberStandard)) /
      (Math.pow(1 + interest, paymentNumberStandard) - 1);
    console.log("mortgageStandard " + mortgageStandard);
    //console.log('addamountValue ' + addamountValue);
    //mortgageStandard = mortgageStandard + addamountValue;
    console.log("mortgageStandard with additional " + mortgageStandard);

    // var standardYears = parseFloat((mortgageStandard*12*years)  / (mortgageStandard*12)).toFixed(1);
    // var biweeklyYears = parseFloat((mortgageStandard*12*years) / (mortgageStandard*13)).toFixed(1);
    var standardYears = parseFloat(
      (mortgageStandard * 12 * term) / (mortgageStandard * 12)
    ).toFixed(1);
    var biweeklyYears = parseFloat(
      (mortgageStandard * 12 * term) / (mortgageStandard * 13)
    ).toFixed(1);

    var interestPaidStandard = principal * yearlyinterest * standardYears;
    var interestPaidBiWeekly = principal * yearlyinterest * biweeklyYears;
    this.setState({ interestPaidStandard: interestPaidStandard });
    this.setState({ interestPaidBiWeekly: interestPaidBiWeekly });
    this.setState({ standardYears: standardYears });
    this.setState({ biweeklyYears: biweeklyYears });
    this.setState({ standardMonths: parseInt(standardYears * 12) });
    this.setState({ biweeklyMonths: parseInt(biweeklyYears * 12) });
    this.setState({ monthlyPayment: mortgageStandard });
    this.setState({
      biWeeklyPayment: mortgageStandard / 2 + parseInt(addamountValue),
    });

    var paidAmount = 0;
    var paidAmountNormal = 0;
    var paidAmountYearLater = 0;
    var outstandingAmount = amountValue;
    var outstandingAmountNormal = amountValue;
    var outstandingAmountYearLater = amountValue;
    //var paymentCount = years*12;
    var paymentCount = term * 12;
    var interestTotalStandard = 0;
    var interestTotalBiweekly = 0;
    var interestTotalBiweeklyYearLater = 0;
    var yearsTotalStandard = 0;
    var yearsTotalBiweekly = 0;
    var yearsTotalBiweeklyYearLater = 0;
    console.log("payment count " + paymentCount);

    var monthCount = 0;
    var monthCountYearLater = 0;
    var standardMonthCount = 0;
    var standardMonthCountYearLater = 0;
    // original chart
    // var generalYearCount = new Date().getFullYear();
    // proposed chart
    var generalYearCount = new Date().getFullYear() - (term - years);
    var principalPaidAreaDataBiweekly = [];
    var principalPaidAreaDataBiweeklyYearLater = [];
    var principalPaidAreaDataStandard = [];
    principalPaidAreaDataStandard.push({
      Biweekly: amountValue,
      Interest: interestTotalStandard,
      InterestBiweekly: interestTotalBiweekly,
      Principal: paidAmountNormal,
      Standard: amountValue,
      name: generalYearCount,
      totalInterestPaidBiweekly: 0,
      totalInterestPaidStandard: 0,
    });

    var generalMonthCount = 0;
    var isFirst = true;
    var isLast = true;
    var isLastYearLater = true;
    var yearValueBiweekly = 0;

    // original chart
    // var yearsAlreadyPaid = term - years;

    // proposed chart
    var yearsAlreadyPaid = 0;

    if (years >= term) {
      yearsAlreadyPaid = 0;
      this.setState({ principalAlreadyPaid: parseInt(0) });
    }
    var yearsPaidCount = 0;
    this.setState({ monthsAlreadyPaid: yearsAlreadyPaid * 12 });
    // schedule
    for (let i = 0; i < paymentCount; i++) {
      generalMonthCount++;

      // if (monthsAlreadyPaid > 0 && monthsPaidCount !== monthsAlreadyPaid && paidAmountNormal<amountValue){
      //   monthsPaidCount++;
      //   let interestpaymentStandard = (outstandingAmountNormal*yearlyinterest)/12;
      //   interestTotalStandard = interestTotalStandard + interestpaymentStandard;
      //   let principalStandard = mortgageStandard - interestpaymentStandard;
      //   outstandingAmountNormal = outstandingAmountNormal - principalStandard;
      //   paidAmountNormal = paidAmountNormal + principalStandard;
      // } else {
      if (paidAmountNormal < amountValue) {
        let interestpaymentStandard =
          (outstandingAmountNormal * yearlyinterest) / 12;
        interestTotalStandard = interestTotalStandard + interestpaymentStandard;
        let principalStandard = mortgageStandard - interestpaymentStandard;
        outstandingAmountNormal = outstandingAmountNormal - principalStandard;
        paidAmountNormal = paidAmountNormal + principalStandard;
        //console.log('normal outstanding amount ' + outstandingAmountNormal + ' | ' + i);
        yearsTotalStandard = i + 1;
      }
      //}

      // biweekly stats
      if (paidAmount < amountValue) {
        standardMonthCount++;
        if (standardMonthCount <= yearsAlreadyPaid * 12) {
          let interestpayment = (outstandingAmount * yearlyinterest) / 12;
          interestTotalBiweekly = interestTotalBiweekly + interestpayment;
          //console.log('interestpayment ' + interestpayment);
          let principal = mortgageStandard - interestpayment;
          //console.log('principal ' + principal);
          // original chart
          // outstandingAmount = outstandingAmount - principal;
          // paidAmount = paidAmount + principal;
          // proposed chart
          outstandingAmount =
            generalYearCount - yearsAlreadyPaid < new Date().getFullYear()
              ? outstandingAmountNormal
              : outstandingAmount - principal;
          paidAmount =
            generalYearCount - yearsAlreadyPaid < new Date().getFullYear()
              ? paidAmountNormal
              : paidAmount + principal;
          //console.log('outstanding amount ' + outstandingAmount + ' | ' + i);
          yearsTotalBiweekly = i + 1;
        } else {
          monthCount++;
          if (monthCount === 12) {
            // second payment month for biweekly
            yearValueBiweekly++;
            let interestpayment = (outstandingAmount * yearlyinterest) / 12;
            //console.log('interestpayment ' + interestpayment);
            interestTotalBiweekly = interestTotalBiweekly + interestpayment;
            let principal =
              mortgageStandard +
              parseInt(addamountValue) -
              interestpayment +
              (mortgageStandard + parseInt(addamountValue));
            //console.log('principal ' + principal);
            // original chart
            // outstandingAmount = outstandingAmount - principal;
            // paidAmount = paidAmount + principal;
            // proposed chart
            outstandingAmount =
              generalYearCount - yearsAlreadyPaid < new Date().getFullYear()
                ? outstandingAmountNormal
                : outstandingAmount - principal;
            paidAmount =
              generalYearCount - yearsAlreadyPaid < new Date().getFullYear()
                ? paidAmountNormal
                : paidAmount + principal;
            //console.log('outstanding amount DEC ' + outstandingAmount + ' | ' + i);
            monthCount = 0;
            //principalPaidAreaDataBiweekly.push({name:yearValueBiweekly, paidBiweekly:paidAmount, })
            //principalPaidEarlyAreaData
            //yearsTotalBiweekly = i + 1;
          } else {
            let interestpayment = (outstandingAmount * yearlyinterest) / 12;
            interestTotalBiweekly = interestTotalBiweekly + interestpayment;
            //console.log('interestpayment ' + interestpayment);
            let principal =
              mortgageStandard + parseInt(addamountValue) - interestpayment;
            //console.log('principal ' + principal);
            // original chart
            // outstandingAmount = outstandingAmount - principal;
            // paidAmount = paidAmount + principal;
            // proposed chart
            outstandingAmount =
              generalYearCount - yearsAlreadyPaid < new Date().getFullYear()
                ? outstandingAmountNormal
                : outstandingAmount - principal;
            paidAmount =
              generalYearCount - yearsAlreadyPaid < new Date().getFullYear()
                ? paidAmountNormal
                : paidAmount + principal;
            //console.log('outstanding amount ' + outstandingAmount + ' | ' + i);
            yearsTotalBiweekly = i + 1;
          }
        }
      } else {
        paidAmount = amountValue;
      }

      // year later biweekly stats
      // if (paidAmountYearLater<amountValue){
      //   standardMonthCountYearLater++;
      //   if ( standardMonthCountYearLater <= (yearsAlreadyPaid*12) ){
      //     let interestpayment = (outstandingAmountYearLater*yearlyinterest)/12;
      //     console.log('standardMonthCountYearLater ' + standardMonthCountYearLater);

      //     interestTotalBiweeklyYearLater = interestTotalBiweeklyYearLater + interestpayment;
      //     //console.log('interestpayment ' + interestpayment);
      //     let principal = mortgageStandard - interestpayment;
      //     //console.log('principal ' + principal);
      //     outstandingAmountYearLater = outstandingAmountYearLater - principal;
      //     paidAmountYearLater = paidAmountYearLater + principal;
      //     //console.log('outstanding amount ' + outstandingAmount + ' | ' + i);
      //     yearsTotalBiweeklyYearLater = i + 1;

      //   } else {
      //     monthCountYearLater++;
      //     if (monthCountYearLater === 12){
      //       if (isFirst === true){
      //         isFirst = false;
      //         monthCountYearLater = 0;
      //         let interestpayment = (outstandingAmount*yearlyinterest)/12;
      //         interestTotalBiweeklyYearLater = interestTotalBiweeklyYearLater + interestpayment;
      //         //console.log('interestpayment ' + interestpayment);
      //         let principal = (mortgageStandard + parseInt(addamountValue)) - interestpayment;
      //         //console.log('principal ' + principal);
      //         outstandingAmountYearLater = outstandingAmountYearLater - principal;
      //         paidAmountYearLater = paidAmountYearLater + principal;
      //         //console.log('outstanding amount ' + outstandingAmount + ' | ' + i);
      //         yearsTotalBiweeklyYearLater = i + 1;

      //       } else {
      //         // second payment month for biweekly
      //         yearValueBiweekly++;
      //         let interestpayment = (outstandingAmount*yearlyinterest)/12;
      //         //console.log('interestpayment ' + interestpayment);
      //         interestTotalBiweeklyYearLater = interestTotalBiweeklyYearLater + interestpayment;
      //         let principal = ((mortgageStandard + parseInt(addamountValue)) - interestpayment) + (mortgageStandard + parseInt(addamountValue)) ;
      //         //console.log('principal ' + principal);
      //         outstandingAmountYearLater = outstandingAmountYearLater - principal;
      //         paidAmountYearLater = paidAmountYearLater + principal;
      //         //console.log('outstanding amount DEC ' + outstandingAmount + ' | ' + i);
      //         monthCountYearLater = 0;
      //         //principalPaidAreaDataBiweekly.push({name:yearValueBiweekly, paidBiweekly:paidAmount, })
      //         //principalPaidEarlyAreaData
      //       }

      //     } else {
      //       let interestpayment = (outstandingAmount*yearlyinterest)/12;
      //       interestTotalBiweeklyYearLater = interestTotalBiweeklyYearLater + interestpayment;
      //       //console.log('interestpayment ' + interestpayment);
      //       let principal = (mortgageStandard + parseInt(addamountValue)) - interestpayment;
      //       //console.log('principal ' + principal);
      //       outstandingAmountYearLater = outstandingAmountYearLater - principal;
      //       paidAmountYearLater = paidAmountYearLater + principal;
      //       //console.log('outstanding amount ' + outstandingAmount + ' | ' + i);
      //       yearsTotalBiweeklyYearLater = i + 1;

      //     }
      //   }

      // } else {
      //   paidAmountYearLater = amountValue;
      // }

      if (generalMonthCount === 12) {
        generalYearCount++;
        generalMonthCount = 0;

        if (yearsPaidCount !== yearsAlreadyPaid) {
          yearsPaidCount++;
          console.log("Already paid " + yearsPaidCount);
          this.setState({ principalAlreadyPaid: parseInt(paidAmountNormal) });
        } else {
          //biweekly
          if (paidAmount >= amountValue) {
            if (isLast === true) {
              principalPaidAreaDataBiweekly.push({
                name: generalYearCount - yearsAlreadyPaid,
                Interest: parseInt(interestTotalBiweekly),
                Principal: parseInt(amountValue),
                Biweekly:
                  outstandingAmount >= 0 ? parseInt(outstandingAmount) : 0,
                Standard:
                  outstandingAmountNormal >= 0
                    ? parseInt(outstandingAmountNormal)
                    : 0,
              });
              // this.setState({totalCostLoanBiweekly:parseInt(amountValue + interestTotalBiweekly)});
              // this.setState({totalInterestPaidBiweekly:parseInt(interestTotalBiweekly)});
              isLast = false;
            } else {
              //principalPaidAreaDataBiweekly.push({name:generalYearCount, interest:parseInt(0), principal:parseInt(0)});
            }
          } else {
            //principalPaidAreaDataBiweekly.push({name:generalYearCount, interest:parseInt(interestTotalBiweekly), principal:parseInt(paidAmount)});
            principalPaidAreaDataBiweekly.push({
              name: generalYearCount - yearsAlreadyPaid,
              Interest: parseInt(interestTotalBiweekly),
              Principal: parseInt(paidAmount),
              Biweekly:
                outstandingAmount >= 0 ? parseInt(outstandingAmount) : 0,
              Standard:
                outstandingAmountNormal >= 0
                  ? parseInt(outstandingAmountNormal)
                  : 0,
            });
          }

          // year later biweekly
          if (paidAmountYearLater >= amountValue) {
            if (isLastYearLater === true) {
              principalPaidAreaDataBiweeklyYearLater.push({
                name: generalYearCount - yearsAlreadyPaid,
                Interest: parseInt(interestTotalBiweeklyYearLater),
                Principal: parseInt(amountValue),
                Total:
                  parseInt(amountValue) +
                  parseInt(interestTotalBiweeklyYearLater),
              });
              isLastYearLater = false;
            } else {
              //principalPaidAreaDataBiweekly.push({name:generalYearCount, interest:parseInt(0), principal:parseInt(0)});
            }
          } else {
            //principalPaidAreaDataBiweekly.push({name:generalYearCount, interest:parseInt(interestTotalBiweekly), principal:parseInt(paidAmount)});
            principalPaidAreaDataBiweeklyYearLater.push({
              name: generalYearCount - yearsAlreadyPaid,
              Interest: parseInt(interestTotalBiweeklyYearLater),
              Principal: parseInt(paidAmountYearLater),
              Total:
                parseInt(paidAmountYearLater) +
                parseInt(interestTotalBiweeklyYearLater),
            });
          }
          if (parseInt(outstandingAmountNormal) === 1) {
            outstandingAmountNormal = 0;
          }
          if (parseInt(outstandingAmount) === 1) {
            outstandingAmount = 0;
          }
          // standard
          principalPaidAreaDataStandard.push({
            name: generalYearCount - yearsAlreadyPaid,
            InterestBiweekly: parseInt(interestTotalBiweekly),
            Interest: parseInt(interestTotalStandard),
            Principal: parseInt(paidAmountNormal),
            totalInterestPaidStandard: 0,
            totalInterestPaidBiweekly: 0,
            Biweekly: outstandingAmount >= 0 ? parseInt(outstandingAmount) : 0,
            Standard:
              outstandingAmountNormal >= 0
                ? parseInt(outstandingAmountNormal)
                : 0,
          });
        }
      }
    }

    this.setState({
      totalCostLoanBiweekly:
        parseInt(amountValue) + parseInt(interestTotalBiweekly),
    });
    this.setState({
      totalInterestPaidBiweekly: parseInt(interestTotalBiweekly),
    });
    this.setState({
      totalCostLoanStandard:
        parseInt(interestTotalStandard) + parseInt(paidAmountNormal),
    });
    this.setState({
      totalInterestPaidStandard: parseInt(interestTotalStandard),
    });

    for (let index = 0; index < principalPaidAreaDataStandard.length; index++) {
      principalPaidAreaDataStandard[index].totalInterestPaidStandard = parseInt(
        interestTotalStandard
      );
      // original chart
      // principalPaidAreaDataStandard[index].totalInterestPaidBiweekly = parseInt(interestTotalBiweekly);
      // proposed chart
      principalPaidAreaDataStandard[index].totalInterestPaidBiweekly =
        principalPaidAreaDataStandard[index].name < new Date().getFullYear()
          ? parseInt(interestTotalStandard)
          : parseInt(interestTotalBiweekly);
    }

    console.log("interestTotalStandard " + interestTotalStandard);
    console.log("interestTotalBiweekly " + interestTotalBiweekly);
    console.log("yearsTotalStandard " + yearsTotalStandard);
    console.log("yearsTotalBiweekly " + yearsTotalBiweekly);
    //console.log('yearsTotalBiweeklyYearLater ' + yearsTotalBiweeklyYearLater);
    console.log("end mortgageStandard " + mortgageStandard);
    console.log("end addamountValue " + addamountValue);

    var interestSavings = parseInt(
      interestTotalStandard - interestTotalBiweekly
    );
    var yearsSavings = parseInt(yearsTotalStandard - yearsTotalBiweekly);
    //var yearsSavingsYearLater = parseInt(yearsTotalBiweekly - yearsTotalBiweeklyYearLater);
    console.log("interestSavings " + interestSavings);
    console.log("yearsSavings " + yearsSavings);

    this.setState({
      principalPaidAreaDataBiweekly: principalPaidAreaDataBiweekly,
    });
    this.setState({
      principalPaidAreaDataStandard: principalPaidAreaDataStandard,
    });
    //this.setState({principalPaidAreaDataBiweeklyYearLater:principalPaidAreaDataBiweeklyYearLater});
    this.setState({ interestTotalStandard: interestTotalStandard });
    this.setState({ interestTotalBiweekly: interestTotalBiweekly });
    this.setState({ yearsTotalStandard: yearsTotalStandard });
    this.setState({ yearsTotalBiweekly: yearsTotalBiweekly });
    //this.setState({yearsSavingsYearLater:yearsSavingsYearLater});
    this.setState({ interestSavings: interestSavings });
    this.setState({ yearsSavings: yearsSavings });

    this.setState({
      data: [
        {
          name: "Standard Interest Paid",
          Standard: interestTotalStandard.toFixed(2),
        },
      ],
    });
    this.setState({
      dataSaved: [
        { name: "Interest Savings", Savings: interestSavings.toFixed(2) },
      ],
    });
  }

  calculateYearLater(amountValue, addamountValue, interestrate, years, term) {
    if (addamountValue == "" || addamountValue == 0) {
      var principal = amountValue;
    } else {
      var principal = parseInt(amountValue); //+ parseInt(addamountValue);
    } //principle / initial amount borrowed
    var interest = interestrate / 100 / 12; //monthly interest rate
    var yearlyinterest = interestrate / 100; //year interest rate
    //var paymentNumberStandard = years * 12; //number of payments months
    var paymentNumberStandard = term * 12; //number of payments months
    //var paymentNumberBiWeekly = years * 26; //number of biweekly payments months

    //monthly mortgage payment
    var mortgageStandard =
      (principal * interest * Math.pow(1 + interest, paymentNumberStandard)) /
      (Math.pow(1 + interest, paymentNumberStandard) - 1);
    console.log("mortgageStandard " + mortgageStandard);
    //console.log('addamountValue ' + addamountValue);
    //mortgageStandard = mortgageStandard + addamountValue;
    console.log("mortgageStandard with additional " + mortgageStandard);

    // var standardYears = parseFloat((mortgageStandard*12*years)  / (mortgageStandard*12)).toFixed(1);
    // var biweeklyYears = parseFloat((mortgageStandard*12*years) / (mortgageStandard*13)).toFixed(1);
    var standardYears = parseFloat(
      (mortgageStandard * 12 * term) / (mortgageStandard * 12)
    ).toFixed(1);
    var biweeklyYears = parseFloat(
      (mortgageStandard * 12 * term) / (mortgageStandard * 13)
    ).toFixed(1);

    var interestPaidStandard = principal * yearlyinterest * standardYears;
    var interestPaidBiWeekly = principal * yearlyinterest * biweeklyYears;
    // this.setState({interestPaidStandard:interestPaidStandard});
    // this.setState({interestPaidBiWeekly:interestPaidBiWeekly});
    // this.setState({standardYears:standardYears});
    // this.setState({biweeklyYears:biweeklyYears});
    // this.setState({standardMonths:parseInt(standardYears*12) });
    // this.setState({biweeklyMonths:parseInt(biweeklyYears*12) });
    // this.setState({monthlyPayment:mortgageStandard});
    // this.setState({biWeeklyPayment:(mortgageStandard/2) + parseInt(addamountValue)});

    var paidAmount = 0;
    var paidAmountNormal = 0;
    var paidAmountYearLater = 0;
    var outstandingAmount = amountValue;
    var outstandingAmountNormal = amountValue;
    var outstandingAmountYearLater = amountValue;
    //var paymentCount = years*12;
    var paymentCount = term * 12;
    var interestTotalStandard = 0;
    var interestTotalBiweekly = 0;
    var interestTotalBiweeklyYearLater = 0;
    var yearsTotalStandard = 0;
    var yearsTotalBiweekly = 0;
    var yearsTotalBiweeklyYearLater = 0;
    console.log("payment count " + paymentCount);

    var monthCount = 0;
    var monthCountYearLater = 0;
    var standardMonthCount = 0;
    var standardMonthCountYearLater = 0;
    var principalPaidAreaDataBiweekly = [];
    var principalPaidAreaDataBiweeklyYearLater = [];
    var principalPaidAreaDataStandard = [];
    var generalMonthCount = 0;
    var generalYearCount = 0;
    var isFirst = true;
    var isLast = true;
    var isLastYearLater = true;
    var yearValueBiweekly = 0;

    var yearsAlreadyPaid = term - years;
    if (years >= term) {
      yearsAlreadyPaid = 0;
      //this.setState({principalAlreadyPaid:parseInt(0)});
    }
    var yearsPaidCount = 0;
    //this.setState({monthsAlreadyPaid:(yearsAlreadyPaid*12)});
    // schedule
    for (let i = 0; i < paymentCount; i++) {
      generalMonthCount++;

      // if (monthsAlreadyPaid > 0 && monthsPaidCount !== monthsAlreadyPaid && paidAmountNormal<amountValue){
      //   monthsPaidCount++;
      //   let interestpaymentStandard = (outstandingAmountNormal*yearlyinterest)/12;
      //   interestTotalStandard = interestTotalStandard + interestpaymentStandard;
      //   let principalStandard = mortgageStandard - interestpaymentStandard;
      //   outstandingAmountNormal = outstandingAmountNormal - principalStandard;
      //   paidAmountNormal = paidAmountNormal + principalStandard;
      // } else {
      if (paidAmountNormal < amountValue) {
        let interestpaymentStandard =
          (outstandingAmountNormal * yearlyinterest) / 12;
        interestTotalStandard = interestTotalStandard + interestpaymentStandard;
        let principalStandard = mortgageStandard - interestpaymentStandard;
        outstandingAmountNormal = outstandingAmountNormal - principalStandard;
        paidAmountNormal = paidAmountNormal + principalStandard;
        //console.log('normal outstanding amount ' + outstandingAmountNormal + ' | ' + i);
        yearsTotalStandard = i + 1;
      }
      //}

      // biweekly stats
      if (paidAmount < amountValue) {
        standardMonthCount++;
        if (standardMonthCount <= yearsAlreadyPaid * 12) {
          let interestpayment = (outstandingAmount * yearlyinterest) / 12;
          interestTotalBiweekly = interestTotalBiweekly + interestpayment;
          //console.log('interestpayment ' + interestpayment);
          let principal = mortgageStandard - interestpayment;
          //console.log('principal ' + principal);
          outstandingAmount = outstandingAmount - principal;
          paidAmount = paidAmount + principal;
          //console.log('outstanding amount ' + outstandingAmount + ' | ' + i);
          yearsTotalBiweekly = i + 1;
        } else {
          monthCount++;
          if (monthCount === 12 && isFirst === true) {
            isFirst = false;
            let interestpayment = (outstandingAmount * yearlyinterest) / 12;
            interestTotalBiweekly = interestTotalBiweekly + interestpayment;
            //console.log('interestpayment ' + interestpayment);
            let principal =
              mortgageStandard + parseInt(addamountValue) - interestpayment;
            //console.log('principal ' + principal);
            outstandingAmount = outstandingAmount - principal;
            paidAmount = paidAmount + principal;
            //console.log('outstanding amount ' + outstandingAmount + ' | ' + i);
            yearsTotalBiweekly = i + 1;
            monthCount = 0;
          } else if (monthCount === 12 && isFirst !== true) {
            // second payment month for biweekly
            yearValueBiweekly++;
            let interestpayment = (outstandingAmount * yearlyinterest) / 12;
            //console.log('interestpayment ' + interestpayment);
            interestTotalBiweekly = interestTotalBiweekly + interestpayment;
            let principal =
              mortgageStandard +
              parseInt(addamountValue) -
              interestpayment +
              (mortgageStandard + parseInt(addamountValue));
            //console.log('principal ' + principal);
            outstandingAmount = outstandingAmount - principal;
            paidAmount = paidAmount + principal;
            //console.log('outstanding amount DEC ' + outstandingAmount + ' | ' + i);
            monthCount = 0;
            //principalPaidAreaDataBiweekly.push({name:yearValueBiweekly, paidBiweekly:paidAmount, })
            //principalPaidEarlyAreaData
          } else {
            let interestpayment = (outstandingAmount * yearlyinterest) / 12;
            interestTotalBiweekly = interestTotalBiweekly + interestpayment;
            //console.log('interestpayment ' + interestpayment);
            let principal =
              mortgageStandard + parseInt(addamountValue) - interestpayment;
            //console.log('principal ' + principal);
            outstandingAmount = outstandingAmount - principal;
            paidAmount = paidAmount + principal;
            //console.log('outstanding amount ' + outstandingAmount + ' | ' + i);
            yearsTotalBiweekly = i + 1;
          }
        }
      } else {
        paidAmount = amountValue;
      }

      if (generalMonthCount === 12) {
        generalYearCount++;
        generalMonthCount = 0;

        if (yearsPaidCount !== yearsAlreadyPaid) {
          yearsPaidCount++;
          console.log("Already paid " + yearsPaidCount);
          //this.setState({principalAlreadyPaid:parseInt(paidAmountNormal)});
        } else {
          //biweekly
          if (paidAmount >= amountValue) {
            if (isLast === true) {
              principalPaidAreaDataBiweekly.push({
                name: generalYearCount - yearsAlreadyPaid,
                Interest: parseInt(interestTotalBiweekly),
                Principal: parseInt(amountValue),
                Total: parseInt(amountValue + interestTotalBiweekly),
              });
              isLast = false;
            } else {
              //principalPaidAreaDataBiweekly.push({name:generalYearCount, interest:parseInt(0), principal:parseInt(0)});
            }
          } else {
            //principalPaidAreaDataBiweekly.push({name:generalYearCount, interest:parseInt(interestTotalBiweekly), principal:parseInt(paidAmount)});
            principalPaidAreaDataBiweekly.push({
              name: generalYearCount - yearsAlreadyPaid,
              Interest: parseInt(interestTotalBiweekly),
              Principal: parseInt(paidAmount),
              Total: parseInt(paidAmount + interestTotalBiweekly),
            });
          }

          // year later biweekly
          if (paidAmountYearLater >= amountValue) {
            if (isLastYearLater === true) {
              principalPaidAreaDataBiweeklyYearLater.push({
                name: generalYearCount - yearsAlreadyPaid,
                Interest: parseInt(interestTotalBiweeklyYearLater),
                Principal: parseInt(amountValue),
                Total: parseInt(amountValue + interestTotalBiweeklyYearLater),
              });
              isLastYearLater = false;
            } else {
              //principalPaidAreaDataBiweekly.push({name:generalYearCount, interest:parseInt(0), principal:parseInt(0)});
            }
          } else {
            //principalPaidAreaDataBiweekly.push({name:generalYearCount, interest:parseInt(interestTotalBiweekly), principal:parseInt(paidAmount)});
            principalPaidAreaDataBiweeklyYearLater.push({
              name: generalYearCount - yearsAlreadyPaid,
              Interest: parseInt(interestTotalBiweeklyYearLater),
              Principal: parseInt(paidAmountYearLater),
              Total: parseInt(
                paidAmountYearLater + interestTotalBiweeklyYearLater
              ),
            });
          }

          // standard
          principalPaidAreaDataStandard.push({
            name: generalYearCount - yearsAlreadyPaid,
            Interest: parseInt(interestTotalStandard),
            Principal: parseInt(paidAmountNormal),
            Total: parseInt(interestTotalStandard + paidAmountNormal),
          });
        }
      }
    }

    console.log("year later interestTotalStandard " + interestTotalStandard);
    console.log("year later interestTotalBiweekly " + interestTotalBiweekly);
    console.log("year later yearsTotalStandard " + yearsTotalStandard);
    console.log("year later yearsTotalBiweekly " + yearsTotalBiweekly);
    //console.log('yearsTotalBiweeklyYearLater ' + yearsTotalBiweeklyYearLater);
    console.log("year later end mortgageStandard " + mortgageStandard);
    console.log("year later end addamountValue " + addamountValue);

    var interestSavings = parseInt(
      interestTotalStandard - interestTotalBiweekly
    );
    var yearsSavings = parseInt(yearsTotalStandard - yearsTotalBiweekly);
    //var yearsSavingsYearLater = parseInt(yearsTotalBiweekly - yearsTotalBiweeklyYearLater);
    console.log("year later interestSavings " + interestSavings);
    console.log("year later yearsSavings " + yearsSavings);

    // this.setState({principalPaidAreaDataBiweekly:principalPaidAreaDataBiweekly});
    // this.setState({principalPaidAreaDataStandard:principalPaidAreaDataStandard});
    this.setState({
      principalPaidAreaDataBiweeklyYearLater: principalPaidAreaDataBiweekly,
    });
    // this.setState({interestTotalStandard:interestTotalStandard});
    // this.setState({interestTotalBiweekly:interestTotalBiweekly});
    // this.setState({yearsTotalStandard:yearsTotalStandard});
    // this.setState({yearsTotalBiweekly:yearsTotalBiweekly});
    this.setState({ yearsSavingsYearLater: yearsSavings });
    this.setState({ interestSavingsYearLater: interestSavings });
    // this.setState({interestSavings:interestSavings});
    // this.setState({yearsSavings:yearsSavings});

    // this.setState({data:[{name: "Standard Interest Paid", Standard: interestTotalStandard.toFixed(2)}]})
    // this.setState({dataSaved:[{name: "Interest Savings",  Savings: interestSavings.toFixed(2)}]})
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  signUp = (e) => {
    if (this.state.isLoading) return;


    var now = Date();
    var nowValue = Math.floor(Date.now() / 1000);

    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();

    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    var dateVal = yyyy.toString() + mm.toString() + dd.toString();

    this.setState({ isLoading: true });
    e.preventDefault();
    let req = { body: {} };
    req.body = {
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      loanAmount: this.state.amountValue,
      interestRate: this.state.interestrate,
      remainingYears: this.state.yearsValue,
      mortGateTerm: this.state.termValue,
      additionalBiweekly: this.state.addamountValue,
      totalInterestSaved: this.state.interestSavings,
      monthsSaved: this.state.yearsSavings,
      accumulatedWealth: parseFloat(
        (this.state.yearsTotalStandard - this.state.yearsTotalBiweekly) *
          this.state.monthlyPayment
      ).toFixed(0),
      yearsToPayOff: (
        (this.state.yearsValue * 12 - this.state.yearsSavings) /
        12
      ).toFixed(1),
      totalCost: this.state.totalCostLoanBiweekly,
      totalInterestPaid: this.state.totalInterestPaidBiweekly,
      percentInterestSaved: parseFloat(
        parseFloat(
          this.state.interestSavings / this.state.totalInterestPaidBiweekly
        ) * 100
      ).toFixed(1),
    };

    axios
      .post(
        `${URL}contact/sync?api_key=${API_KEY}`,
        {
          contact: {
            email: req.body.email,
            firstName: req.body.firstName,
            lastName: req.body.lastName,
          },
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(({ data }) => {
        //update contact
        axios.post(
          `${URL}fieldValues?api_key=${API_KEY}`,
          {
            fieldValue: {
              contact: data.contact.id,
              field: CUSTOM_FIELDS.LOAN_AMOUNT,
              value: "$" + this.numberWithCommas(req.body.loanAmount),
            },
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        axios.post(
          `${URL}fieldValues?api_key=${API_KEY}`,
          {
            fieldValue: {
              contact: data.contact.id,
              field: CUSTOM_FIELDS.INTEREST_RATE,
              value: req.body.interestRate,
            },
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        axios.post(
          `${URL}fieldValues?api_key=${API_KEY}`,
          {
            fieldValue: {
              contact: data.contact.id,
              field: CUSTOM_FIELDS.REMAINING_YEARS,
              value: req.body.remainingYears,
            },
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        axios.post(
          `${URL}fieldValues?api_key=${API_KEY}`,
          {
            fieldValue: {
              contact: data.contact.id,
              field: CUSTOM_FIELDS.MORTGAGE_TERM,
              value: req.body.mortGateTerm,
            },
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        axios.post(
          `${URL}fieldValues?api_key=${API_KEY}`,
          {
            fieldValue: {
              contact: data.contact.id,
              field: CUSTOM_FIELDS.ADDITIONAL_BIWEEKLY,
              value: "$" + this.numberWithCommas(req.body.additionalBiweekly),
            },
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        axios.post(
          `${URL}fieldValues?api_key=${API_KEY}`,
          {
            fieldValue: {
              contact: data.contact.id,
              field: CUSTOM_FIELDS.TOTAL_INTEREST_SAVED,
              value: "$" + this.numberWithCommas(req.body.totalInterestSaved),
            },
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        axios.post(
          `${URL}fieldValues?api_key=${API_KEY}`,
          {
            fieldValue: {
              contact: data.contact.id,
              field: CUSTOM_FIELDS.MONTHS_SAVED,
              value: req.body.monthsSaved,
            },
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        axios.post(
          `${URL}fieldValues?api_key=${API_KEY}`,
          {
            fieldValue: {
              contact: data.contact.id,
              field: CUSTOM_FIELDS.ACCUMULATED_WEALTH,
              value: "$" + this.numberWithCommas(req.body.accumulatedWealth),
            },
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        axios.post(
          `${URL}fieldValues?api_key=${API_KEY}`,
          {
            fieldValue: {
              contact: data.contact.id,
              field: CUSTOM_FIELDS.YEARS_TO_PAY_OFF,
              value: req.body.yearsToPayOff,
            },
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        axios.post(
          `${URL}fieldValues?api_key=${API_KEY}`,
          {
            fieldValue: {
              contact: data.contact.id,
              field: CUSTOM_FIELDS.TOTAL_COST,
              value: "$" + this.numberWithCommas(req.body.totalCost),
            },
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        axios.post(
          `${URL}fieldValues?api_key=${API_KEY}`,
          {
            fieldValue: {
              contact: data.contact.id,
              field: CUSTOM_FIELDS.TOTAL_INTEREST_PAID,
              value: "$" + this.numberWithCommas(req.body.totalInterestPaid),
            },
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        axios.post(
          `${URL}fieldValues?api_key=${API_KEY}`,
          {
            fieldValue: {
              contact: data.contact.id,
              field: CUSTOM_FIELDS.PERCENT_INTEREST_SAVED,
              value: req.body.percentInterestSaved + "%",
            },
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        axios.post(
          `${URL}fieldValues?api_key=${API_KEY}`,
          {
            fieldValue: {
              contact: data.contact.id,
              field: CUSTOM_FIELDS.YEAR_LATER_INTEREST_SAVINGS,
              value:
                "$" +
                this.numberWithCommas(
                  (
                    this.state.interestSavings -
                    this.state.interestSavingsYearLater
                  ).toFixed(0)
                ),
            },
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        axios.post(
          `${URL}fieldValues?api_key=${API_KEY}`,
          {
            fieldValue: {
              contact: data.contact.id,
              field: CUSTOM_FIELDS.YEAR_LATER_MONTHS_SAVED,
              value: parseInt(
                this.state.yearsSavings - this.state.yearsSavingsYearLater
              ),
            },
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        axios.post(
          `${URL}fieldValues?api_key=${API_KEY}`,
          {
            fieldValue: {
              contact: data.contact.id,
              field: CUSTOM_FIELDS.YEAR_LATER_MONTHS_SAVED_TOTAL_VALUE,
              value:
                "$" +
                this.numberWithCommas(
                  parseFloat(
                    this.state.monthlyPayment *
                      parseInt(
                        this.state.yearsSavings -
                          this.state.yearsSavingsYearLater
                      )
                  ).toFixed(0)
                ),
            },
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        axios.post(
          `${URL}fieldValues?api_key=${API_KEY}`,
          {
            fieldValue: {
              contact: data.contact.id,
              field: CUSTOM_FIELDS.EACH_MONTH_DELAY_COST,
              value:
                "$" +
                this.numberWithCommas(
                  (
                    this.state.interestSavings /
                    this.state.yearsSavings /
                    12
                  ).toFixed(2)
                ),
            },
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        axios.post(
          `${URL}fieldValues?api_key=${API_KEY}`,
          {
            fieldValue: {
              contact: data.contact.id,
              field: CUSTOM_FIELDS.YEARS_SAVED,
              value: (this.state.yearsSavings / 12).toFixed(1),
            },
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        setTimeout(() => {
          //window.location.href = "https://go.save7years.com/";
          window.location.href = "https://go.save7years.com/order-page1591726883461";
        }, 1000);
      })
      .then(response => { 
        console.log(response)
      })
      .catch((error) => {
        firebase
        .database()
        .ref("errors")
        .push({
          error:error.toString(),
          errorLocation:'registration',
          timestamp: now,
          timestampValue: nowValue,
        });

        console.log(error)
      });


    firebase
      .database()
      .ref("dated/pageloads/registered/" + dateVal)
      .update({
        dateVal: dateVal,
        timestamp: now,
        timestampValue: nowValue,
      });

    firebase
      .database()
      .ref("pageloads/registered/")
      .push({
        dateVal: dateVal,
        timestamp: now,
        timestampValue: nowValue,
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        loanAmount: this.state.amountValue,
        interestRate: this.state.interestrate,
        remainingYears: this.state.yearsValue,
        mortGateTerm: this.state.termValue,
        additionalBiweekly: this.state.addamountValue,
        totalInterestSaved: this.state.interestSavings,
        monthsSaved: this.state.yearsSavings,
        accumulatedWealth: parseFloat(
          (this.state.yearsTotalStandard - this.state.yearsTotalBiweekly) *
            this.state.monthlyPayment
        ).toFixed(0),
        yearsToPayOff: (
          (this.state.yearsValue * 12 - this.state.yearsSavings) /
          12
        ).toFixed(1),
        totalCost: this.state.totalCostLoanBiweekly,
        totalInterestPaid: this.state.totalInterestPaidBiweekly,
        percentInterestSaved: parseFloat(
          parseFloat(
            this.state.interestSavings / this.state.totalInterestPaidBiweekly
          ) * 100
        ).toFixed(1),
      });
  };

  render() {
    const {
      amountValue,
      yearsValue,
      damountValue,
      interestrate,
      paymentsperyear,
    } = this.state;

    return (
      <>
        <div
          textAlign="center"
          className="outerwrapper"
          style={{ width: "80%", minHeight: "100vh" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "rows",
              justifyContent: "flex-start",
            }}
            className="outerwrapper"
          >
            <h1
              style={{
                textAlign: "center",
                fontSize: 30,
                fontWeight: "bold",
                marginBottom: 10,
              }}
            ></h1>
            {this.state.interestSavings === 0 && (
              <p
                style={{
                  textAlign: "center",
                  fontSize: 25,
                  width: "80%",
                  margin: "auto",
                  marginTop: "20px",
                  color: "var(--app-title)",
                }}
              >
                Discover how many years you can shave off your mortgage by
                paying biweekly instead of monthly.
              </p>
            )}
            {this.state.interestSavings !== 0 && (
              <p
                style={{
                  textAlign: "center",
                  fontSize: 25,
                  width: "80%",
                  margin: "auto",
                  marginTop: "20px",
                  color: "var(--app-title)",
                }}
              >
                Act now and save up to{" "}
                <b style={{ fontSize: 30, margin: 3 }}>
                  ${this.numberWithCommas(this.state.interestSavings)}{" "}
                </b>
                and{" "}
                <b style={{ fontSize: 30, margin: 3 }}>
                  {this.state.yearsSavings} months{" "}
                </b>
                on your mortgage by paying biweekly!
              </p>
            )}
            {/* {this.state.isSticky && (
              <div className="fixed-message">
                <div>
                  <p>
                    Act now and save up to{" "}
                    <b>${this.numberWithCommas(this.state.interestSavings)} </b>
                    and <b>{this.state.yearsSavings} months </b>
                    on your mortgage by paying biweekly!{" "}
                    <a href="#" onClick={() => this.signUp()}>
                      Get started now
                    </a>
                  </p>
                </div>
              </div>
            )} */}
            {this.state.isSticky && (
              <div className="fixed-message-top">
                <div>
                  <p>
                    Act now and save up to{" "}
                    <b>${this.numberWithCommas(this.state.interestSavings)} </b>
                    and <b>{this.state.yearsSavings} months </b>
                    on your mortgage by paying biweekly!{" "}
                    <a
                      href="#"
                      onClick={this.signUp}
                      disabled={this.state.isLoading}
                    >
                      {this.state.isLoading && (
                        <i
                          className="fa fa-spinner fa-spin"
                          style={{ marginRight: 5 }}
                        ></i>
                      )}
                      Start saving now
                    </a>
                  </p>
                </div>
              </div>
            )}
            <div className="App" id="App">
              <div className="calcu-form-wrapper">
                <div className="calcu-form">
                  <div style={{ margin: 15 }}>
                    <h4 style={{ margin: 5 }}>Loan Amount</h4>
                    <input
                      pattern="[0-9]*"
                      inputMode="decimal"
                      type="number"
                      min="0"
                      className="input"
                      value={this.state.amountValue}
                      onChange={this.handleAmountChange}
                      onBlur={this.handleAmountBlur}
                    />
                  </div>

                  <div style={{ margin: 15 }}>
                    <h4 style={{ margin: 5 }}>Interest Rate</h4>
                    <input
                      pattern="[0-9]*"
                      inputMode="decimal"
                      type="number"
                      min="0"
                      className="input"
                      value={this.state.interestrate}
                      onChange={this.handleinterestChange}
                      onBlur={this.handleinterestBlur}
                    />
                  </div>

                  <div style={{ margin: 15 }}>
                    <h4 style={{ margin: 5 }}>Remaining Years</h4>
                    <input
                      pattern="[0-9]*"
                      inputMode="decimal"
                      type="number"
                      min="0"
                      className="input"
                      value={this.state.yearsValue}
                      onChange={this.handleYearChange}
                      onBlur={this.handleYearBlur}
                    />
                  </div>

                  <div style={{ margin: 15 }}>
                    <h4 style={{ margin: 5 }}>Mortgage Term</h4>

                    {this.state.termValue === 15 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "row",
                          margin: 5,
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <button
                          style={{
                            width: "60px",
                            height: "40px",
                            borderRadius: "5px",
                            marginRight: "10px",
                          }}
                          onClick={() => this.setMortgageTerm(15)}
                          className="ui button selected"
                        >
                          15
                        </button>
                        <button
                          style={{
                            backgroundColor: "#eee",
                            width: "60px",
                            height: "40px",
                            borderRadius: "5px",
                            marginRight: "10px",
                          }}
                          onClick={() => this.setMortgageTerm(30)}
                          className="ui button"
                        >
                          30
                        </button>
                      </div>
                    )}
                    {this.state.termValue === 30 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "row",
                          margin: 5,
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <button
                          style={{
                            backgroundColor: "#eee",
                            width: "60px",
                            height: "40px",
                            borderRadius: "5px",
                            marginRight: "10px",
                          }}
                          onClick={() => this.setMortgageTerm(15)}
                          className="ui button"
                        >
                          15
                        </button>
                        <button
                          style={{
                            width: "60px",
                            height: "40px",
                            borderRadius: "5px",
                            marginRight: "10px",
                          }}
                          onClick={() => this.setMortgageTerm(30)}
                          className="ui button selected"
                        >
                          30
                        </button>
                      </div>
                    )}
                  </div>

                  <div style={{ margin: 15 }}>
                    <h4 style={{ margin: 5 }}>Additional Biweekly Payment</h4>
                    <input
                      pattern="[0-9]*"
                      inputMode="decimal"
                      type="number"
                      min="0"
                      className="input"
                      value={this.state.addamountValue}
                      onChange={this.handleAddAmountChange}
                      onBlur={this.handleAddAmountBlur}
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <div
                    className="chart-wrapper"
                    ref={(el) => (this.biWeeklyDimensionRef = el)}
                  >
                    {/* <div className="chart-title">
                      <p>
                        <strong>Biweekly Time and Money Savings</strong>
                      </p>

                      <div className="subtitle">
                        <p style={{ width: "250px" }}>
                          {" "}
                          Finish paying your mortgage{" "}
                          <b>{this.state.yearsSavings} months </b>
                          early AND save{" "}
                          <b>
                            ${this.numberWithCommas(this.state.interestSavings)}{" "}
                          </b>
                          in interest.
                        </p>
                      </div>
                    </div> */}
                    <div className="charts">
                      <div>
                        <div className="chart-header">
                          <p>
                            <strong>TOTAL INTEREST SAVINGS</strong>
                          </p>
                          <p>
                            <strong>
                              $
                              {this.numberWithCommas(
                                this.state.totalInterestPaidStandard -
                                  this.state.totalInterestPaidBiweekly
                              )}
                            </strong>
                          </p>
                        </div>
                        <p className="chart-top-text">
                          Total Standard Interest:{" "}
                          <strong>
                            $
                            {this.numberWithCommas(
                              this.state.totalInterestPaidStandard
                            )}
                          </strong>
                        </p>
                        <ResponsiveContainer
                          width={this.state.biWeeklyDimension.width}
                          aspect={2}
                        >
                          <BarChart
                            // width={this.state.biWeeklyDimension.width}
                            // height={250}
                            barGap={20}
                            data={[
                              {
                                name: "",
                                standard: this.state.totalInterestPaidStandard,
                                biweekly: this.state.totalInterestPaidBiweekly,
                              },
                            ]}
                            layout="vertical"
                          >
                            <XAxis
                              type="number"
                              stroke="#000"
                              tickFormatter={(value) => ""}
                            />
                            <YAxis
                              dataKey="name"
                              type="category"
                              stroke="#000"
                            />
                            <Tooltip
                              cursor={{
                                stroke: "red",
                                strokeWidth: 0,
                                fill: "rgba(255,255,255,0.3)",
                              }}
                              formatter={(value) =>
                                "$" + this.numberWithCommas(value)
                              }
                              content={
                                <CustomBarTooltip
                                  totalInterestPaidStandard={
                                    this.state.totalInterestPaidStandard
                                  }
                                  totalInterestPaidBiweekly={
                                    this.state.totalInterestPaidBiweekly
                                  }
                                />
                              }
                            />
                            <Bar
                              name="Total Standard Interest"
                              dataKey="standard"
                              fill="var(--graph-bar-standard)"
                            />
                            <Bar
                              name="Biweekly Interest"
                              dataKey="biweekly"
                              fill="var(--graph-bar-biweekly)"
                            />
                          </BarChart>
                        </ResponsiveContainer>

                        <p className="chart-bottom-text">
                          Biweekly Interest:{" "}
                          <strong>
                            $
                            {this.numberWithCommas(
                              this.state.totalInterestPaidBiweekly
                            )}
                          </strong>
                        </p>
                        <div className="metric-chart">
                          <p>
                            <strong style={{ whiteSpace: "nowrap" }}>
                              TOTAL PRINCIPAL + INTEREST
                            </strong>
                          </p>
                          <p>Standard</p>
                          <p className="metric-value">
                            <strong>
                              $
                              {this.numberWithCommas(
                                this.state.totalCostLoanStandard
                              )}
                            </strong>
                          </p>
                          <p className="biweekly">With Biweekly Payments</p>
                          <p className="biweekly metric-value">
                            <strong>
                              $
                              {this.numberWithCommas(
                                parseFloat(this.state.totalCostLoanBiweekly)
                              )}
                            </strong>
                          </p>

                          <span>
                            <i
                              className="biweekly fa fa-check"
                              style={{ marginRight: 10 }}
                            />
                            You'll save{" "}
                            {"$" +
                              this.numberWithCommas(
                                parseInt(this.state.interestSavings)
                              )}{" "}
                            in interest which is{" "}
                            {parseFloat(
                              parseFloat(
                                this.state.interestSavings /
                                  this.state.totalInterestPaidBiweekly
                              ) * 100
                            ).toFixed(1)}
                            %!!
                          </span>
                        </div>
                      </div>

                      <div>
                        <div className="chart-header">
                          <p>
                            <strong>TOTAL TIME SAVINGS</strong>
                          </p>
                          <p>
                            <strong>
                              {(this.state.yearsSavings / 12).toFixed(1)} years
                            </strong>
                          </p>
                        </div>
                        <p style={{ opacity: 0, marginTop: -10 }}>none</p>
                        <ResponsiveContainer
                          width={this.state.biWeeklyDimension.width}
                          aspect={2}
                        >
                          <LineChart
                            // width={this.state.biWeeklyDimension.width}
                            // height={300}
                            data={this.state.principalPaidAreaDataStandard}
                            margin={{ top: 10, right: 30, left: 20, bottom: 0 }}
                          >
                            <XAxis
                              dataKey="name"
                              stroke="#000"
                              interval={
                                this.state.principalPaidAreaDataStandard
                                  .length - 2
                              }
                              tickFormatter={(value) =>
                                value ===
                                new Date().getFullYear() -
                                  (this.state.termValue - this.state.yearsValue)
                                  ? ""
                                  : value
                              }
                            />
                            <YAxis
                              stroke="#000"
                              tickFormatter={(value) => ""}
                            />
                            <Tooltip
                              cursor={{
                                stroke: "red",
                                strokeWidth: 0,
                                fill: "rgba(255,255,255,0.3)",
                              }}
                              formatter={(value) =>
                                "$" + this.numberWithCommas(value)
                              }
                              content={
                                <CustomTooltip
                                  totalInterestPaidStandard={
                                    this.state.totalInterestPaidStandard
                                  }
                                  totalInterestPaidBiweekly={
                                    this.state.totalInterestPaidBiweekly
                                  }
                                />
                              }
                            />

                            <Line
                              dot={false}
                              type="monotone"
                              dataKey="Biweekly"
                              stroke="var(--graph-line-biweekly)"
                              strokeWidth={3}
                            />
                            <Line
                              dot={false}
                              type="monotone"
                              dataKey="Standard"
                              stroke="var(--graph-line-standard)"
                              strokeWidth={3}
                            />
                          </LineChart>
                        </ResponsiveContainer>
                        <p style={{ opacity: 0, marginTop: -25 }}>none</p>
                        <div className="metric-chart">
                          <p>
                            <strong>PAYOFF DATE</strong>
                          </p>
                          <p>Standard</p>
                          <p className="metric-value">
                            <strong>
                              {moment([
                                new Date().getFullYear() +
                                  parseInt(this.state.standardYears),
                                11,
                                31,
                              ])
                                .subtract(
                                  this.state.termValue - this.state.yearsValue,
                                  "year"
                                )
                                .format("YYYY")}
                            </strong>
                          </p>
                          <p className="biweekly">With Biweekly Payments</p>
                          <p className="biweekly metric-value">
                            <strong>
                              {moment([
                                new Date().getFullYear() +
                                  parseInt(this.state.standardYears),
                                11,
                                31,
                              ])
                                .subtract(
                                  this.state.termValue - this.state.yearsValue,
                                  "year"
                                )
                                .subtract(this.state.yearsSavings, "month")
                                .format("YYYY")}
                            </strong>
                          </p>

                          <span>
                            <i
                              className="biweekly fa fa-check"
                              style={{ marginRight: 10 }}
                            />{" "}
                            You'll save{" "}
                            {"$" +
                              this.numberWithCommas(
                                parseInt(this.state.interestSavings)
                              )}{" "}
                            in interest which is{" "}
                            {parseFloat(
                              parseFloat(
                                this.state.interestSavings /
                                  this.state.totalInterestPaidBiweekly
                              ) * 100
                            ).toFixed(1)}
                            %!!
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Media
                  query="(min-width: 769px)"
                  render={() => (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                      // ref={(el) => (this.biWeeklyTableRef = el)}
                    >
                      <table
                        className="desktop-metric-table"
                        // width={this.state.biWeeklyTable.width}
                        // style={{ width: "100%" }}
                      >
                        <thead>
                          <tr
                            style={{
                              background: "var(--table-emphasize-background)",
                              color: "var(--table-emphasize)",
                            }}
                          >
                            <th>Payment Method</th>
                            <th>Months Saved</th>
                            <th>Total Interest Saved</th>
                            <th
                              style={{
                                borderRight: "10px solid var(--table-border)",
                              }}
                            >
                              Accumulated Wealth
                            </th>
                            <th
                              style={{
                                background: "var(--table-header-background)",
                              }}
                            >
                              Years to pay off
                            </th>

                            <th
                              style={{
                                background: "var(--table-header-background)",
                              }}
                            >
                              Total Cost
                            </th>
                            <th
                              style={{
                                background: "var(--table-header-background)",
                              }}
                            >
                              Total Interest Paid
                            </th>
                            <th
                              style={{
                                background: "var(--table-header-background)",
                              }}
                            >
                              Percent Interest Saved
                            </th>
                          </tr>
                        </thead>
                        <tr
                          style={{
                            background: "var(--table-body-background)",
                            color: "var(--table-body-text)",
                          }}
                        >
                          <td className="emphasize-td">Biweekly</td>{" "}
                          <td className="emphasize-td">
                            {this.state.yearsSavings}
                          </td>{" "}
                          <td className="emphasize-td">
                            {"$" +
                              this.numberWithCommas(
                                parseInt(this.state.interestSavings)
                              )}
                          </td>{" "}
                          <td
                            className="emphasize-td"
                            style={{
                              borderRight: "10px solid var(--table-border)",
                            }}
                          >
                            {"$" +
                              this.numberWithCommas(
                                parseFloat(
                                  (this.state.yearsTotalStandard -
                                    this.state.yearsTotalBiweekly) *
                                    this.state.monthlyPayment
                                ).toFixed(0)
                              )}
                          </td>
                          <td>
                            {(
                              (this.state.yearsValue * 12 -
                                this.state.yearsSavings) /
                              12
                            ).toFixed(1)}
                          </td>{" "}
                          <td>
                            {"$" +
                              this.numberWithCommas(
                                parseInt(this.state.totalCostLoanBiweekly)
                              )}
                          </td>{" "}
                          <td>
                            {"$" +
                              this.numberWithCommas(
                                parseInt(this.state.totalInterestPaidBiweekly)
                              )}
                          </td>{" "}
                          <td>
                            {parseFloat(
                              parseFloat(
                                this.state.interestSavings /
                                  this.state.totalInterestPaidBiweekly
                              ) * 100
                            ).toFixed(1)}
                            %
                          </td>{" "}
                        </tr>
                        <tr
                          style={{
                            background: "var(--table-body-background)",
                            color: "gray",
                          }}
                        >
                          <td>Standard</td>
                          <td>0</td> <td>0</td>
                          <td
                            style={{
                              borderRight: "10px solid var(--table-border)",
                            }}
                          >
                            0
                          </td>
                          <td>{this.state.yearsValue}</td>{" "}
                          <td>
                            {"$" +
                              this.numberWithCommas(
                                parseInt(this.state.totalCostLoanStandard)
                              )}
                          </td>{" "}
                          <td>
                            {"$" +
                              this.numberWithCommas(
                                parseInt(this.state.totalInterestPaidStandard)
                              )}
                          </td>{" "}
                          <td>0%</td>{" "}
                        </tr>
                      </table>
                    </div>
                  )}
                />
                <Media
                  query="(max-width: 768px)"
                  render={() => (
                    <div cellPadding="10">
                      <div className="metric-table-container">
                        <table
                          className="metric-table"
                          style={{
                            // boxShadow: "0 8px 6px -6px black",
                            marginBottom: 10,
                          }}
                        >
                          <tbody className="metric-body">
                            <tr className="metric-row">
                              <td
                                className="emphasize-td"
                                style={{
                                  background:
                                    "var(--table-emphasize-background)",
                                }}
                              >
                                Payment Method
                              </td>

                              <td className="emphasize-td">Standard</td>

                              <td className="emphasize-td">Biweekly</td>
                            </tr>
                            <tr className="metric-row">
                              <td
                                className="emphasize-td"
                                style={{
                                  background:
                                    "var(--table-emphasize-background)",
                                }}
                              >
                                Months Saved{" "}
                              </td>
                              <td>0</td>
                              <td className="emphasize-td">
                                {this.state.yearsSavings}
                              </td>
                            </tr>
                            <tr className="metric-row">
                              <td
                                className="emphasize-td"
                                style={{
                                  background:
                                    "var(--table-emphasize-background)",
                                }}
                              >
                                Total Interest Saved{" "}
                              </td>
                              <td>0</td>
                              <td className="emphasize-td">
                                {"$" +
                                  this.numberWithCommas(
                                    parseInt(this.state.interestSavings)
                                  )}
                              </td>
                            </tr>
                            <tr className="metric-row emphasize-td">
                              <td
                                className="emphasize-td"
                                style={{
                                  borderBottom:
                                    "10px solid var(--table-border)",
                                  background:
                                    "var(--table-emphasize-background)",
                                }}
                              >
                                Accumulated Wealth
                              </td>
                              <td
                                style={{
                                  borderBottom:
                                    "10px solid var(--table-border)",
                                }}
                              >
                                0
                              </td>
                              <td
                                className="emphasize-td"
                                style={{
                                  borderBottom:
                                    "10px solid var(--table-border)",
                                }}
                              >
                                {"$" +
                                  this.numberWithCommas(
                                    parseFloat(
                                      (this.state.yearsTotalStandard -
                                        this.state.yearsTotalBiweekly) *
                                        this.state.monthlyPayment
                                    ).toFixed(0)
                                  )}
                              </td>
                            </tr>
                            <tr className="metric-row">
                              <td>Years to pay off </td>
                              <td>{this.state.yearsValue}</td>
                              <td>
                                {(
                                  (this.state.yearsValue * 12 -
                                    this.state.yearsSavings) /
                                  12
                                ).toFixed(1)}
                              </td>
                            </tr>

                            <tr className="metric-row">
                              <td>Total Cost </td>
                              <td>
                                {"$" +
                                  this.numberWithCommas(
                                    parseInt(this.state.totalCostLoanStandard)
                                  )}
                              </td>
                              <td>
                                {"$" +
                                  this.numberWithCommas(
                                    parseInt(this.state.totalCostLoanBiweekly)
                                  )}
                              </td>
                            </tr>

                            <tr className="metric-row">
                              <td>Total Interest Paid </td>
                              <td>
                                {"$" +
                                  this.numberWithCommas(
                                    parseInt(
                                      this.state.totalInterestPaidStandard
                                    )
                                  )}
                              </td>
                              <td>
                                {"$" +
                                  this.numberWithCommas(
                                    parseInt(
                                      this.state.totalInterestPaidBiweekly
                                    )
                                  )}
                              </td>
                            </tr>
                            <tr className="metric-row">
                              <td>Percent Interest Saved </td>
                              <td>0%</td>
                              <td>
                                {parseFloat(
                                  parseFloat(
                                    this.state.interestSavings /
                                      this.state.totalInterestPaidBiweekly
                                  ) * 100
                                ).toFixed(1)}
                                %
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="letter">
          <h1 style={{ textAlign: "center" }}>
            Here’s what will happen if you start paying biweekly today
          </h1>
          <br></br>
          <div className="paragraph">
            <p>
              The Biweekly Strategy rewards people who take immediate action. In
              fact, even starting one year earlier could make a huge difference
              in your savings. Let’s use your current mortgage as an example:
            </p>
            <br />
            <p>
              Your loan balance to date is{" "}
              {"$" +
                this.numberWithCommas(
                  parseInt(
                    this.state.amountValue - this.state.principalAlreadyPaid
                  )
                )}
              . If you implemented the Biweekly Strategy one year from today,
              you’d save {parseInt(this.state.yearsSavingsYearLater)} monthly
              payments… but if you started today, you’d save{" "}
              {parseInt(this.state.yearsSavings)}. That means{" "}
              {"$" +
                this.numberWithCommas(
                  (
                    this.state.interestSavings -
                    this.state.interestSavingsYearLater
                  ).toFixed(0)
                )}{" "}
              extra saved, just from starting one year early!
            </p>
            <br />
            <p>
              And that’s only the beginning.{" "}
              {"$" +
                this.numberWithCommas(
                  (
                    this.state.interestSavings -
                    this.state.interestSavingsYearLater
                  ).toFixed(0)
                )}{" "}
              is how much you’d save in the first year, but you’d continue to
              save more every year thereafter, allowing you to pay off your
              mortgage in{" "}
              {(
                (this.state.yearsValue * 12 - this.state.yearsSavings) /
                12
              ).toFixed(1)}{" "}
              years instead of {this.state.yearsValue}. (That’s{" "}
              {(this.state.yearsSavings / 12).toFixed(1)} years early!)
            </p>
            <br />
            <p>
              In total, you’d save{" "}
              {parseInt(
                this.state.yearsTotalStandard - this.state.yearsTotalBiweekly
              )}{" "}
              payments - or{" "}
              {"$" +
                this.numberWithCommas(
                  parseFloat(
                    (this.state.yearsTotalStandard -
                      this.state.yearsTotalBiweekly) *
                      this.state.monthlyPayment
                  ).toFixed(0)
                )}
              ! How long would it take you to earn that much at your job?
            </p>
            <br />

            <p>
              {"$" + this.numberWithCommas(this.state.interestSavings)} of this
              total would have gone to unnecessary interest payments instead of
              your kid’s college fund, travel expenses, or a pool in the
              backyard.
            </p>
            <br />

            <p>
              Plus, since you’ll receive these savings as you go, you have the
              option to redirect this money to your retirement or other
              investment accounts where it can grow (instead of sitting in the
              banks’ pockets).
            </p>
            <br />

            <p>
              Every day you wait will cost you an average of{" "}
              {"$" +
                this.numberWithCommas(
                  (
                    this.state.interestSavings /
                    this.state.yearsSavings /
                    12
                  ).toFixed(2)
                )}{" "}
              in lost interest savings, so if you’re interested in saving
              thousands with the Biweekly Strategy, act NOW!
            </p>
          </div>
          <div
            style={{
              width: "100%",
              marginTop: "50px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1 style={{ textAlign: "center" }}>
              Get access to our Biweekly Savings Program!
            </h1>
            <button
              onClick={this.signUp}
              className="button-sign-up"
              disabled={this.state.isLoading}
            >
              {this.state.isLoading && (
                <i
                  className="fa fa-spinner fa-spin"
                  style={{ marginRight: 5 }}
                ></i>
              )}
              Get started now
            </button>
            <br />
            <small style={{fontSize:'9px', margin:'10px', paddingTop:'50px', color:'gray',}}>
              Future Wealth equals principal and interest times number of Months
              Saved. Note: All figures are deemed reliable but are not
              guaranteed.  
            </small>
            <small style={{fontSize:'9px', margin:'10px', color:'gray',}}>
              
            </small>
          </div>
        </div>
      </>
    );
  }
}

export default Calculator;

/*
    <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-start', width:'80%', margin:'auto', marginTop:'20px', textAlign:"center", }}>
            <p style={{fontSize:20, color:'var(--maroon-1)'}}>You can save </p>
            <p style={{marginLeft:5, marginRight:1, marginBottom:0, fontWeight:'bold', color:'var(--maroon-1)', fontSize:25}}>${this.state.interestSavings}</p>
            <p style={{marginLeft:5, fontSize:20, color:'var(--maroon-1)'}}> and </p>
            <p style={{marginLeft:5, marginRight:1, marginBottom:0, fontWeight:'bold', color:'var(--maroon-1)', fontSize:25}}>{this.state.yearsSavings}</p>
            <p style={{marginLeft:5, fontSize:20, color:'var(--maroon-1)'}}> years off your mortgage by paying bi-weekly!</p>
          </div>
      <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-start', width:'80%', margin:'auto', marginTop:'20px', textAlign:"center", }}>
            </div>



            <div className="box">

  <div className="bxcell">
  <h4 style={{margin:5}}>Loa
   pattern="[0-9]*"
   inputMode="decimal" className="input" value={this.state.amountValue} onChange={this.handleAmountChange}
   type="number"
   min="0"
      />
  </div>


  <div className="bxcell">
        <h4 style={{margin:5
         pattern="[0-9]*"
        inputMode="decimal"" className="input" value={this.state.interestrate} onChange={this.handleinterestChange}
        type="number"
        min="0"
        />
</div>
<div className="bxcell">
        <h4 style={{margin:5}}>
          Over {yearsValue} year{yearsValue > 1 && "s"}
        </h4>
         pattern="[0-9]*"
         inputMode="decimal" className="input" value={this.state.yearsValue} onChange={this.handleYearChange}
         type="number"
         min="0"
        />
        <h4></h4>
        </div>
  </div>

<h4 style={{margin:5}}>Over {yearsValue} year{yearsValue > 1 && "s"}</h4>





calculatePayment(amountValue, addamountValue, interestrate, years, term) {

    if(addamountValue=='' || addamountValue ==0)
    {
      var principal = amountValue;
    }
    else{
    var principal = parseInt(amountValue); //+ parseInt(addamountValue);
    } //principle / initial amount borrowed
    var interest = interestrate / 100 / 12; //monthly interest rate
    var yearlyinterest = interestrate / 100 ; //year interest rate
    var paymentNumberStandard = years * 12; //number of payments months
    var paymentNumberBiWeekly = years * 26; //number of biweekly payments months


    //monthly mortgage payment
    var mortgageStandard = principal * interest * (Math.pow(1 + interest, paymentNumberStandard)) / (Math.pow(1 + interest, paymentNumberStandard) - 1);
    console.log('mortgageStandard ' + mortgageStandard);
    //console.log('addamountValue ' + addamountValue);
    //mortgageStandard = mortgageStandard + addamountValue;
    console.log('mortgageStandard with additional ' + mortgageStandard);

    var standardYears = parseFloat((mortgageStandard*12*years)  / (mortgageStandard*12)).toFixed(1);
    var biweeklyYears = parseFloat((mortgageStandard*12*years) / (mortgageStandard*13)).toFixed(1);

    var interestPaidStandard = principal*yearlyinterest*standardYears;
    var interestPaidBiWeekly = principal*yearlyinterest*biweeklyYears;
    this.setState({interestPaidStandard:interestPaidStandard});
    this.setState({interestPaidBiWeekly:interestPaidBiWeekly});
    this.setState({standardYears:standardYears});
    this.setState({biweeklyYears:biweeklyYears});
    this.setState({standardMonths:parseInt(standardYears*12) });
    this.setState({biweeklyMonths:parseInt(biweeklyYears*12) });
    this.setState({monthlyPayment:mortgageStandard});
    this.setState({biWeeklyPayment:(mortgageStandard/2) + parseInt(addamountValue)});


    var paidAmount = 0;
    var paidAmountNormal = 0;
    var outstandingAmount = amountValue;
    var outstandingAmountNormal = amountValue;
    var paymentCount = years*12;
    var interestTotalStandard = 0;
    var interestTotalBiweekly = 0
    var yearsTotalStandard = 0;
    var yearsTotalBiweekly = 0;
    console.log('payment count ' + paymentCount);

    var monthCount = 0;
    var principalPaidAreaDataBiweekly = [];
    var principalPaidAreaDataStandard = [];
    var generalMonthCount=0;
    var generalYearCount=0;
    var isLast = true;

    // schedule
    for (let i = 0; i < paymentCount; i++) {
      generalMonthCount++;

        if (paidAmountNormal<amountValue){
            let interestpaymentStandard = (outstandingAmountNormal*yearlyinterest)/12;
            interestTotalStandard = interestTotalStandard + interestpaymentStandard;
            let principalStandard = mortgageStandard - interestpaymentStandard;
            outstandingAmountNormal = outstandingAmountNormal - principalStandard;
            paidAmountNormal = paidAmountNormal + principalStandard;
            //console.log('normal outstanding amount ' + outstandingAmountNormal + ' | ' + i);
            yearsTotalStandard = i + 1;
        }



      if (paidAmount<amountValue){

          monthCount++;
          if (monthCount === 12){
            // second payment month for biweekly
            yearValueBiweekly++;
            let interestpayment = (outstandingAmount*yearlyinterest)/12;
            //console.log('interestpayment ' + interestpayment);
            interestTotalBiweekly = interestTotalBiweekly + interestpayment;
            let principal = ((mortgageStandard + parseInt(addamountValue)) - interestpayment) + (mortgageStandard + parseInt(addamountValue)) ;
            //console.log('principal ' + principal);
            outstandingAmount = outstandingAmount - principal;
            paidAmount = paidAmount + principal;
            //console.log('outstanding amount DEC ' + outstandingAmount + ' | ' + i);
            monthCount = 0;
            //principalPaidAreaDataBiweekly.push({name:yearValueBiweekly, paidBiweekly:paidAmount, })

            //principalPaidEarlyAreaData
          } else {
            let interestpayment = (outstandingAmount*yearlyinterest)/12;
            interestTotalBiweekly = interestTotalBiweekly + interestpayment;
            //console.log('interestpayment ' + interestpayment);
            let principal = (mortgageStandard + parseInt(addamountValue)) - interestpayment;
            //console.log('principal ' + principal);
            outstandingAmount = outstandingAmount - principal;
            paidAmount = paidAmount + principal;
            //console.log('outstanding amount ' + outstandingAmount + ' | ' + i);
            yearsTotalBiweekly = i + 1;

          }



      } else {
        paidAmount = amountValue;
      }

      if (generalMonthCount === 12){
        generalYearCount++;
        generalMonthCount = 0;
        if (paidAmount >= amountValue){
          if (isLast === true){
            principalPaidAreaDataBiweekly.push({name:generalYearCount, Interest:parseInt(interestTotalBiweekly), Principal:parseInt(amountValue), Total:parseInt(amountValue + interestTotalBiweekly)});
            isLast = false;
          } else {
            //principalPaidAreaDataBiweekly.push({name:generalYearCount, interest:parseInt(0), principal:parseInt(0)});
          }

        } else {
          //principalPaidAreaDataBiweekly.push({name:generalYearCount, interest:parseInt(interestTotalBiweekly), principal:parseInt(paidAmount)});
          principalPaidAreaDataBiweekly.push({name:generalYearCount, Interest:parseInt(interestTotalBiweekly), Principal:parseInt(paidAmount), Total:parseInt(paidAmount + interestTotalBiweekly)});
        }

        principalPaidAreaDataStandard.push({name:generalYearCount, Interest:parseInt(interestTotalStandard), Principal:parseInt(paidAmountNormal), Total:parseInt(interestTotalStandard + paidAmountNormal)});
      }

    }


    console.log('interestTotalStandard ' + interestTotalStandard);
    console.log('interestTotalBiweekly ' + interestTotalBiweekly);
    console.log('yearsTotalStandard ' + yearsTotalStandard);
    console.log('yearsTotalBiweekly ' + yearsTotalBiweekly);
    console.log('end mortgageStandard ' + mortgageStandard);
    console.log('end addamountValue ' + addamountValue);

    var interestSavings = parseInt(interestTotalStandard - interestTotalBiweekly);
    var yearsSavings = parseInt(yearsTotalStandard - yearsTotalBiweekly);
    console.log('interestSavings '+ interestSavings);
    console.log('yearsSavings '+ yearsSavings);

    this.setState({principalPaidAreaDataBiweekly:principalPaidAreaDataBiweekly});
    this.setState({principalPaidAreaDataStandard:principalPaidAreaDataStandard});
    this.setState({interestTotalStandard:interestTotalStandard});
    this.setState({interestTotalBiweekly:interestTotalBiweekly});
    this.setState({yearsTotalStandard:yearsTotalStandard});
    this.setState({yearsTotalBiweekly:yearsTotalBiweekly});
    this.setState({interestSavings:interestSavings});
    this.setState({yearsSavings:yearsSavings});

    this.setState({data:[{name: "Standard Interest Paid", Standard: interestTotalStandard.toFixed(2)}]})
    this.setState({dataSaved:[{name: "Interest Savings",  Savings: interestSavings.toFixed(2)}]})

  }







  <div>

  <Media query="(max-width: 768px)" render={() =>
  (
    <div  style={{ height:600, marginTop:'0px', marginBottom:'0px', display:'flex', flexWrap:'wrap', overflow:'hidden', alignItems:'left'}}>
      <BarChart
        barGap={50}
        width={300}
        height={300}
        data={this.state.dataSaved}
        margin={{ top: 5, right: 5, bottom: 5, left: 5 }}

      >

        <XAxis dataKey="name" stroke="var(--maroon-1)" />
        <YAxis stroke="var(--maroon-1)" />
        <Tooltip cursor={{ stroke:'red', strokeWidth: 0, fill:'rgba(255,255,255,0.3)' }} />


        <Bar dataKey="Savings"
        //fill="var(--maroon-1)"
        fill="#8d2055"
         />
      </BarChart>


      <BarChart
            barGap={50}
            width={300}
            height={300}
            data={this.state.data}
            margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
            className="Charts"
          >

            <XAxis dataKey="name" stroke="var(--maroon-1)" />
            <YAxis stroke="var(--maroon-1)" />
            <Tooltip cursor={{ stroke: 'red', strokeWidth: 0, fill:'rgba(255,255,255,0.3)' }} />

            <Bar dataKey="Standard" fill="var(--maroon-1)"
              //<Bar dataKey="BiWeekly" fill="var(--maroon-1)" />
            />

          </BarChart>
      </div>
 )}
 />

      <div>
        <Media query="(min-width: 768px)" render={() =>
          (
            <div  style={{ marginTop:'0px', marginBottom:'40px', display:'flex', flexWrap:'wrap', overflow:'hidden',}}>
                   <BarChart
        barGap={50}
        width={300}
        height={300}
        data={this.state.dataSaved}
        margin={{ top: 5, right: 5, bottom: 5, left: 5 }}

      >

        <XAxis dataKey="name" stroke="var(--maroon-1)" />
        <YAxis stroke="var(--maroon-1)" />
        <Tooltip cursor={{ stroke:'red', strokeWidth: 0, fill:'rgba(255,255,255,0.3)' }} />


        <Bar dataKey="Savings"
        //fill="var(--maroon-1)"
        fill="#8d2055"
         />
      </BarChart>

            <BarChart
            barGap={50}
            width={300}
            height={300}
            data={this.state.data}
            margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
            className="Charts"
          >

            <XAxis dataKey="name" stroke="var(--maroon-1)" />
            <YAxis stroke="var(--maroon-1)" />
            <Tooltip cursor={{ stroke: 'red', strokeWidth: 0, fill:'rgba(255,255,255,0.3)' }} />

            <Bar dataKey="Standard" fill="var(--maroon-1)"
              //<Bar dataKey="BiWeekly" fill="var(--maroon-1)" />
            />

          </BarChart>


          </div>

          )}
        />
      </div>

      </div>




    <Media query="(min-width: 768px)" render={() =>
                  (

        <table cellPadding="20" style={{width:'100%'}}>

        <tr style={{background:'#D96682',color:'var(--maroon-1)'}}>
          <td style={{color:'var(--maroon-1)', fontWeight:'bold'}}>Bi-Weekly</td><td></td>
        <td style={{background:'#D96682'}}>&nbsp;</td>
        <td style={{color:'var(--maroon-1)'}}>Monthly</td><td></td>
        </tr>


        <tr style={{background:'var(--maroon-1)',color:'var(--maroon)'}}>
          <td>Bi-Weekly Payment</td><td>${parseFloat(this.state.biWeeklyPayment).toFixed(2)}</td>
        <td style={{background:'#D96682'}}>&nbsp;</td>
        <td style={{color:'gray'}}>Monthly Payment</td><td style={{color:'gray'}}>${parseFloat(this.state.monthlyPayment).toFixed(2)}</td>
        </tr>


        <tr style={{background:'var(--maroon-1)',color:'var(--maroon)'}}>

          <td>Total Interest Paid</td><td>${parseFloat(this.state.interestTotalBiweekly).toFixed(0)}</td>
        <td style={{background:'#D96682'}}>&nbsp;</td>
        <td style={{color:'gray'}}>Total Interest Paid</td><td style={{color:'gray'}}>${parseFloat(this.state.interestTotalStandard).toFixed(0)}</td>
        </tr>


        <tr style={{background:'var(--maroon-1)',color:'var(--maroon)'}}>

          <td>Pay-off Time (months)</td><td>{this.state.yearsTotalBiweekly}</td>
        <td style={{background:'#D96682'}}>&nbsp;</td>
        <td style={{color:'gray'}}>Pay-off Time (months)</td><td style={{color:'gray'}}>{this.state.yearsTotalStandard}</td>

        </tr>

      </table>

)}
/>

  <table cellPadding="20" style={{width:'100%'}}>

        <tr style={{background:'#D96682',color:'var(--maroon-1)'}}>
          <td style={{color:'var(--maroon-1)', fontWeight:'bold'}}>Biweekly</td><td></td>

        </tr>


        <tr style={{background:'var(--maroon-1)',color:'var(--maroon)'}}>
          <td>Biweekly payment</td><td>${parseFloat(this.state.biWeeklyPayment).toFixed(2)}</td>
        </tr>


        <tr style={{background:'var(--maroon-1)',color:'var(--maroon)'}}>

          <td>Total Interest Paid</td><td>${parseFloat(this.state.interestTotalBiweekly).toFixed(0)}</td>
        </tr>


        <tr style={{background:'var(--maroon-1)',color:'var(--maroon)'}}>

          <td>Pay-off Time (months)</td><td>{this.state.yearsTotalBiweekly}</td>

        </tr>



        <tr style={{background:'#D96682',color:'var(--maroon-1)'}}>
        <td style={{color:'var(--maroon-1)'}}>Monthly</td><td></td>
        </tr>


        <tr style={{background:'var(--maroon-1)',color:'var(--maroon)'}}>
        <td style={{color:'gray'}}>Monthly Payment</td><td style={{color:'gray'}}>${parseFloat(this.state.monthlyPayment).toFixed(2)}</td>
        </tr>


        <tr style={{background:'var(--maroon-1)',color:'var(--maroon)'}}>

        <td style={{color:'gray'}}>Total Interest Paid</td><td style={{color:'gray'}}>${parseFloat(this.state.interestTotalStandard).toFixed(0)}</td>
        </tr>


        <tr style={{background:'var(--maroon-1)',color:'var(--maroon)'}}>

        <td style={{color:'gray'}}>Pay-off Time (months)</td><td style={{color:'gray'}}>{this.state.yearsTotalStandard}</td>

        </tr>



      </table>



    <div style={{display:'flex', flexDirection:'row', background:'#D96682',padding:20, marginTop:'0px',
      boxShadow:'0px 0px 1px 1px #8D2055', width:'100%', overflow:'auto'}}>
       </div>


*/
