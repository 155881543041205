import React, { Component } from "react";
import Calculator from "./Calculator";
import IntroQuestion from "./IntroQuestion";

class Masterform extends Component {
  state = {
    disableFirstQuestion: false,
    step: 0,
    email: "",
    fullName: "",
    Amount: 500000,
    int: 3.75,
    Totalyears: 30,
    mortgageTerm: 30,
    initialValue: {},
  };

  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  setActiveSlide = (step) => {
    this.setState({
      step,
    });
  };

  handleChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  getUrlVars = () => {
    var vars = {};
    var parts = window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        vars[key] = value;
      }
    );
    return vars;
  };

  componentWillMount = () => {
    const email = this.getUrlVars()["email"];
    const name = this.getUrlVars()["name"];
    this.setState({
      email,
      fullName: name ? name.replace("%20", " ") : "",
    });

    if (email && name) {
      this.setState({
        step: 1,
        disableFirstQuestion: true,
      });
    }

    this.setState({
      initialValue: this.state,
    });
  };
  render() {
    const {
      disableFirstQuestion,
      step,
      Amount,
      Totalyears,
      int,
      mortgageTerm,
      email,
      fullName,
      initialValue,
    } = this.state;
    const values = {
      Amount,
      Totalyears,
      mortgageTerm,
      int,
      email,
      fullName,
    };
    if (step < 5) {
      return (
        <IntroQuestion
          nextStep={this.nextStep}
          prevStep={this.prevStep}
          handleChange={this.handleChange}
          setActiveSlide={this.setActiveSlide}
          values={values}
          step={step}
          initialValue={initialValue}
          disableFirstQuestion={disableFirstQuestion}
        />
      );
    }
    return <Calculator values={values} />;
  }
}

export default Masterform;
