export const API_KEY =
  "6f6fe3b824ddd9d576cf15a14f126c2679350824c3c06a462325cdc7aef10065a845b800";

export const URL = "https://afmcmoney.api-us1.com/api/3/";

// export const API_KEY =
//   "bada014d58d85395ac873493b7909e3b1fe66aafc580b7f37a07bfa703cfa55e6d2a3b4d";

// export const URL = "https://benson0012.api-us1.com/api/3/";

export const CUSTOM_FIELDS = {
  LOAN_AMOUNT: 1,
  INTEREST_RATE: 2,
  REMAINING_YEARS: 3,
  MORTGAGE_TERM: 4,
  ADDITIONAL_BIWEEKLY: 5,
  TOTAL_INTEREST_SAVED: 6,
  MONTHS_SAVED: 7,
  ACCUMULATED_WEALTH: 8,
  YEARS_TO_PAY_OFF: 9,
  TOTAL_COST: 10,
  TOTAL_INTEREST_PAID: 11,
  PERCENT_INTEREST_SAVED: 12,
  YEAR_LATER_INTEREST_SAVINGS: 15,
  YEAR_LATER_MONTHS_SAVED: 16,
  YEAR_LATER_MONTHS_SAVED_TOTAL_VALUE: 17,
  EACH_MONTH_DELAY_COST: 18,
  YEARS_SAVED: 19,
};

export const SUBSCRIBE_TO_LIST = 1;
export const SUBSCRIBE_LIST_ID = 1;
