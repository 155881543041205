import React, { Component } from "react";

import Masterform from "../Masterform";
import Blog from "../Blog";
import Analytics from "../Analytics";
import { Switch, Route, Router } from "./../util/router.js";

import * as firebase from "firebase/app";
import "firebase/auth";

firebase.initializeApp({
  apiKey: "AIzaSyBiy99AZApf6xqTG9SmdVEQbak7HKiRsC4",
  authDomain: "biweekly.firebaseapp.com",
  databaseURL: "https://biweekly.firebaseio.com",
  projectId: "biweekly",
  storageBucket: "biweekly.appspot.com",
  messagingSenderId: "478777111639",
  appId: "1:478777111639:web:b95d3ee1db5d661077df12",
  measurementId: "G-LD3KM1PEHH",
});

// const THEMES = ["blue-theme", "green-theme"];
//const THEMES = ["red-theme", "red-theme"];
const THEMES = ["light-blue-theme", "light-green-theme"];

class App extends Component {
  state = {
    variant: undefined,
  };

  componentDidMount = async () => {
    const EXP_ID = "aShCzc14Qt6LZa_lxXVfKg";

    if (window.dataLayer) {
      await window.dataLayer.push({ event: "optimize.activate" });
    }
    this.intervalId = setInterval(() => {
      if (window.google_optimize !== undefined) {
        const variant = window.google_optimize.get(EXP_ID);
        var body = document.body;

        body.classList.add(THEMES[variant]);

        this.setState({ variant });
        clearInterval(this.intervalId);
      }
    }, 100);
  };

  render = () => {
    return (
      <Router>
        <div id="app">
          <Switch>
            <Route exact path="/app" component={Masterform} />

            <Route exact path="/" component={Blog} />

            <Route exact path="/analytics" component={Analytics} />

            <Route
              component={({ location }) => {
                if (location.pathname.substring(1)) {
                }

                return (
                  <div
                    style={{
                      padding: "50px",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    The page <code>{location.pathname}</code> could not be
                    found.
                  </div>
                );
              }}
            />
          </Switch>
        </div>
      </Router>
    );
  };
}

export default App;
//<Route component={PostPage} />
