import React, { Component } from "react";
import axios from "axios";
import "semantic-ui-css/semantic.min.css";
import { Form, Button, TextArea, Container } from "semantic-ui-react";
import "../styles/Blog.scss";
import firebase from "firebase";
import { withRouter } from "react-router-dom";
import { URL, API_KEY, SUBSCRIBE_LIST_ID, SUBSCRIBE_TO_LIST } from "../config";
import IMG_1 from "../assets/alexander.jpg";
import IMG_2 from "../assets/jason.jpg";
import IMG_3 from "../assets/roberto.jpg";
import IMG_4 from "../assets/ryoji.jpg";
import COINS_IMG from "../assets/coins.jpg";
import ReactPixel from 'react-facebook-pixel';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { Experiment, Variant } from 'react-google-optimize';
import {
  faCheckSquare,
  faCoffee,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";

library.add(fab, faCheckSquare, faCoffee);

class Blog extends Component {
  state = {
    email: "",
    success: "",
    error: "",
    name: "",
    isLoading: false,
  };

  componentDidMount() {
    ReactPixel.init('683240455519158');
    ReactPixel.pageView(); 	

    var body = document.body;
    body.classList.add("blog-site");
    const script = document.createElement("script");
    //<div class="_form_1"></div><script src="https://afmcmoney.activehosted.com/f/embed.php?id=1" type="text/javascript" charset="utf-8"></script>
    //script.src = 'https://chinnasoftwares3.activehosted.com/f/embed.php?id=1';
    // script.src = 'https://afmcmoney.activehosted.com/f/embed.php?id=1';
    // script.async = true;

    //document.body.appendChild(script);
    var now = Date();
    var nowValue = Math.floor(Date.now() / 1000);
    // firebase.database().ref("metrics/blogLoads/").push({
    //   timestamp: now,
    //   timestampValue: nowValue,
    // });

    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    var dateVal = yyyy.toString() + mm.toString() + dd.toString();

    firebase
      .database()
      .ref("dated/pageloads/blog/" + dateVal)
      .update({
        dateVal: dateVal,
        timestamp: now,
        timestampValue: nowValue,
        //visit: firebase.database.ServerValue.increment(1),
      });

    firebase.database().ref("pageloads/blog/").push({
      dateVal: dateVal,
      timestamp: now,
      timestampValue: nowValue,
    });
  }

  componentWillUnmount = () => {
    var body = document.body;
    body.classList.remove("blog-site");
  };

  sendEmail() {
    var now = Date();
    var nowValue = Math.floor(Date.now() / 1000);
    this.setState({ isLoading: true });
    if (this.state.email.includes("@")) {
      if (
        this.state.email !== undefined &&
        this.state.email.trim() !== "" &&
        this.state.email !== null
      ) {
        firebase
          .database()
          .ref("emailSignUps/")
          .push({
            timestamp: now,
            timestampValue: nowValue,
            email: this.state.email,
            name: this.state.name,
          })
          .then(() => {
            const firstName =
              this.state.name.split(" ").slice(0, -1).join(" ") || "";
            const lastName =
              this.state.name.split(" ").slice(-1).join(" ") || "";

            axios
              .post(
                `${URL}contact/sync?api_key=${API_KEY}`,
                {
                  contact: {
                    email: this.state.email,
                    firstName,
                    lastName,
                  },
                },
                {
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                }
              )
              .then(({ data }) => {
                axios.post(
                  `${URL}contactLists?api_key=${API_KEY}`,
                  {
                    contactList: {
                      list: SUBSCRIBE_LIST_ID,
                      contact: data.contact.id,
                      status: SUBSCRIBE_TO_LIST,
                    },
                  },
                  {
                    headers: {
                      "Content-Type": "application/x-www-form-urlencoded",
                    },
                  }
                );
              });
            setTimeout(() => {
              window.location.href =
                window.location.origin +
                `/app?email=${this.state.email}&name=${this.state.name}`;
            }, 1000);
          });
      } else {
        this.setState({ error: "Please enter a valid email." });
        this.setState({ success: "" });
      }
    } else {
      this.setState({ error: "Please enter a valid email." });
      this.setState({ success: "" });
    }
  }

  handleEmailChange = (event) => {
    //console.log('this.state.email ' + this.state.email);

    this.setState({ email: event.target.value });
  };
  handleNameChange = (event) => {
    //console.log('this.state.email ' + this.state.email);

    this.setState({ name: event.target.value });
  };

  render() {
    return (
      <>
        <Container
          style={{
            width: "100%",
            color: "#333",
            margin: "0px",
            paddingBottom: "100px",
          }}
        >


    <div
            className="blog-container"
            style={{
              borderRadius: "0px",
              margin: "auto",
              width: "80%",
              marginTop:'200px',
            }}
          >
            {/* <h1 className="title">
              {" "}
              25 Year Old Exposes How Big Banks Are Charging Homeowners 27.78%
              Extra Interest—and the Secret to Paying off Your Mortgage Seven
              Years Early{" "}
            </h1> */}
               <h1 className="title">
              {" "}
              Discover How Much You Can Save With A Bi-Weekly Payment Schedule{" "}
            </h1>
          </div>
    
    
  



          {/* <div
            style={{
              alignItems: "center",
              width: "100%",
              textAlign: "center",
            }}
          >
            <img
              src={IMG_1}
              alt=""
              style={{
                width: "90%",
                maxWidth: "100%",
                margin: "20px",
                marginBottom: "0px",
                height: "auto",
              }}
            ></img>
          </div> */}
          <div
            className="blog-container"
            style={{
              borderRadius: "0px",
              margin: "auto",
              paddingBottom: 30,
            }}
          >
            {/* <div className="tittle-footer">
              <div className="user-container">
                <div className="user">
                  

                  <div>
                    <p className="author">Ross Heaton</p>
                    <span style={{ fontSize: "12px", color: "#333" }}>
                      May 23, 2020 - 4:18 pm
                    </span>
                  </div>
                </div>
                <div className="soc-med">
                 
                </div>
              </div>
            </div>

            <div></div>
            <br /> */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div className="message-wrapper">
                {/* <p
                  style={{
                    marginBottom: "30px",
                  }}
                >
                  Most homeowners don’t know that their bank intentionally set
                  up their mortgage payment plan to{" "}
                  <strong>
                    charge them{" "}
                    <u>thousands of dollars per year in additional interest.</u>
                  </strong>{" "}
                  <br />
                  <br />
                  Twenty-five-year-old <strong>Ross Heaton</strong> discovered
                  this in his New York State licensing MLO (mortgage loan
                  originator) class. According to his teacher,{" "}
                  <strong>
                    a typical mortgage amortization schedule was based on a
                    thirteen-month calendar year
                  </strong>
                  , despite homeowners paying on a twelve-month calendar year.
                  <br />
                  <br />
                  <div
                    style={{
                      alignItems: "center",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                  

                    <img
                      src={IMG_2}
                      alt=""
                      style={{
                        width: "90%",
                        maxWidth: "100%",
                        margin: "20px",
                        height: "auto",
                      }}
                    ></img>
                  </div>
                  <br />
                  This may not sound too incriminating or even relevant at
                  first, but...
                </p>

                <h1>
                  This Simple Schedule Difference Is Jacking Up Your Interest
                  Owed By 27.78%
                </h1>
                <p>
                  Most mortgages are calculated using “simple interest”, meaning
                  that the interest is calculated on a daily basis. As home
                  prices climb and payments keep going up, more of your
                  hard-earned dollars go to interest charges. In fact, in the
                  first ten years of your mortgage, only 15% is applied to
                  principal. <br />
                  <br />
                  Here’s an example:
                  <br />
                  <br /> Let’s say that you have a $150,000 loan at 6% interest
                  for 30 years. You’ve made 120 monthly payments over 10 years
                  at $899.33 per month for a total pay of over $107,919. Your
                  remaining balance would be $125,527, as only $24,472 went
                  toward principal.
                </p>

                <h1>“Everyone else pays monthly… so how bad can it be?”</h1>
                <p>
                  A lot worse than what the Big Banks have led you to believe.
                  <br />
                  <br />
                  <strong>
                    On a monthly payment schedule, you’ll pay approximately 2.28
                    times the amount you originally borrowed.
                  </strong>{" "}
                  After paying your mortgage for 10 years, you’ll still owe
                  84.78% of what you borrowed, and after 15 years, you’ll still
                  owe 72.56%. So if you paid $100,000 on your home over 10
                  years, only $20,000 of that would have gone to principal.
                </p>

                <h1>The Secret Thirteenth Payment You’re Missing</h1>
                <p>
                  As you know not all months are created equal. Some have 31
                  days, some 30, and February just 28. So if you’re making
                  “monthly payments” why wouldn’t the payment change based on
                  the number of days? After all, you’re using your home 365 days
                  per year.
                  <br />
                  <br />
                  Aside from the fact, having different payments would be
                  confusing, banks saw an opportunity.
                  <br />
                  <br />
                  They structured their “months” to align with the fewest days
                  possible...28. And then applied that payment to the other 11
                  months of the year.
                  <br />
                  <br />
                  Your mortgage is set up on a 28 day billing cycle, with what
                  is essentially “forgiveness” for any remaining days. Meaning
                  you aren’t penalized for not paying those days…
                  <br />
                  <br />
                  At least not in the form of a late fee, insufficient payment
                  or fine of some sort.
                  <br />
                  <br />
                  But you are most definitely getting penalized...they just
                  never tell you.
                  <br />
                  <br />
                  While they aren’t charging for the extra 2-3 days per month,
                  they are allowing the interest on those days to compound.
                  <br />
                  <br />
                  And that compounding is why in the first 10-15 years of your
                  mortgage, you barely dent the principal balance.
                  <br />
                  <br />
                  You’re essentially only paying for 336 days (28 days x 12
                  months = 336 days). That leaves 29 days (nearly a full month)
                  unpaid for to aggressively compound and prevent you from
                  paying down your loan.
                  <br />
                  <br />
                  After all, the longer the banks can keep your balance higher,
                  the more interest you end up paying.
                  <br />
                  <br />
                  As a result of this clever trickery by our “friends” on Wall
                  Street, this 13th payment goes unpaid 98% of the time.
                  <br />
                  <br />
                  And unlike traditional payments which pay toward both interest
                  and some principal, this thirteenth payment has the potency of
                  EIGHT traditional payments to principal.
                  <br />
                </p> */}

             <div className="sign-up-container-box" style={{display:'flex', flexDirection:'column', width:'100%', borderStyle:'solid', borderWidth:'0px',borderColor:'gray', marginBottom:'30px',marginTop:'30px', padding:'30px', justifyContent:'center', alignItems:'center'}}>


         <h2 style={{textAlign:'center'}}></h2>

<div
  className="sign-up-container"
  style={{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  }}
>
  <input
    value={this.state.name}
    onChange={this.handleNameChange}
    placeholder="Name"
    style={{
      padding: 5,
      borderRadius: 3,
      color: "gray",
      width: 300,
      height: 40,
      marginTop: "10px",
    }}
  ></input>
  <input
    value={this.state.email}
    onChange={this.handleEmailChange}
    placeholder="Email"
    style={{
      padding: 5,
      borderRadius: 3,
      color: "gray",
      width: 300,
      height: 40,
      marginTop: "10px",
    }}
  ></input>
  <button
    style={{
      padding: 5,
      borderRadius: 3,
      //color: "gray",
      width: 300,
      height: 40,
      marginTop: "10px",
    }}
    onClick={() => this.sendEmail()}
    disabled={this.state.isLoading}
  >
    {this.state.isLoading && (
      <i className="fa fa-spinner fa-spin" />
    )}
    <span style={{ marginLeft: 5 }}>Get started now</span>
  </button>
</div>


           </div>     
                
         {/* <div className="sign-up-container-box" style={{display:'flex', flexDirection:'column', width:'100%', borderStyle:'solid', borderWidth:'2px',borderColor:'gray', marginBottom:'30px',marginTop:'30px', padding:'30px', justifyContent:'center', alignItems:'center'}}>


         <h2 style={{textAlign:'center'}}>Use Our Free Calculator to Discover How Much You Can Save On Your Mortgage</h2>

<div
  className="sign-up-container"
  style={{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  }}
>
  <input
    value={this.state.name}
    onChange={this.handleNameChange}
    placeholder="Name"
    style={{
      padding: 5,
      borderRadius: 3,
      color: "gray",
      width: 240,
      height: 40,
      marginTop: "10px",
    }}
  ></input>
  <input
    value={this.state.email}
    onChange={this.handleEmailChange}
    placeholder="Email"
    style={{
      padding: 5,
      borderRadius: 3,
      color: "gray",
      width: 240,
      height: 40,
      marginTop: "10px",
    }}
  ></input>
  <button
    style={{
      padding: 5,
      borderRadius: 3,
      //color: "gray",
      width: 240,
      height: 40,
      marginTop: "10px",
    }}
    onClick={() => this.sendEmail()}
    disabled={this.state.isLoading}
  >
    {this.state.isLoading && (
      <i className="fa fa-spinner fa-spin" />
    )}
    <span style={{ marginLeft: 5 }}>Get started now</span>
  </button>
</div>


           </div>        */}
         


                
                
                {/* <div
                  style={{
                    alignItems: "center",
                    width: "100%",
                    margin: "10px",
                    textAlign: "center",
                  }}
                >
               
                  <img
                    src={IMG_3}
                    alt=""
                    style={{
                      width: "90%",
                      margin: "20px",
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  ></img>
                </div> */}

                {/* <h1>
                  3 Ways To Adjust Your Amortization Schedule—And Beat The Big
                  Banks At Their Own Game
                </h1>
                <p>
                  Being no stranger to the fact that Big Banks tend to get what
                  they want (even when homeowners are well aware of their
                  tactics), Ross knew that forming a solution to this problem
                  was up to him. Banks wouldn’t be changing their way of
                  calculating interest any time soon. After all, they were
                  profiting greatly off this monthly payment plan!
                  <br />
                  <br />
                  The only workaround would be for homeowners to adjust to this
                  schedule and use it to their own benefit. Ross wanted to level
                  the playing field by setting up a fair amortization schedule:
                  one in which homeowners would make an amount of payments that
                  covered all 365 days of the year.
                  <br />
                  <br />
                  He came up with three ways to achieve this:
                  <br />
                  <br />
                  <strong>
                    Option #1: Homeowners make one full extra mortgage payment
                    at some point during the year
                  </strong>
                  <br />
                  <br />
                  This is a great idea in theory. Yes, this would bring their
                  amount of annual payments up to thirteen… but there’s a reason
                  hardly anyone does this (despite it being a fairly well-known
                  strategy). People simply can’t afford to throw an extra
                  mortgage payment at their house. It’s not the type of money
                  most people have on hand at any one time, especially if they
                  budget on a monthly basis.
                  <br />
                  <br />
                  <strong>
                    Option #2: Homeowners divide their mortgage payment by
                    twelve and add this amount to their monthly payments
                  </strong>
                  <br />
                  <br />
                  Like the first option, this would enable them to make the
                  equivalent of thirteen annual mortgage payments.
                  Unfortunately, this option is hardly an improvement from the
                  first. Although this doesn’t require a large lump sum of money
                  and is more feasible for monthly budgeters, mortgage companies
                  don’t tend to allow partial payments, making this infeasible
                  for most borrowers.
                  <br />
                  <br />
                  <strong>
                    Option #3: Homeowners pay half of their mortgage payment
                    twice per month (aka the Biweekly Strategy)
                  </strong>
                  <br />
                  <br />
                  The Biweekly Strategy is the easiest and most effective way to
                  pay for those extra 29 days. Not only does it line up with
                  most people’s biweekly paychecks, but it allows you to make
                  one full payment to principal each year, and you won’t even
                  feel it because you’ve budgeted for it. This process sounds
                  simple—and it is!—but the ensuing results are massive.
                </p>

                <h1>
                  Ross Started Sharing the Biweekly Strategy With His Clients…
                  and the Banks Weren’t Happy
                </h1>
                <p>
                  The reason Ross was immediately drawn to the Biweekly
                  Strategy—and decided to make it the focus of his career
                  path—was because of the massive benefits it would provide his
                  clients.
                  <br />
                  <br />
                  <strong>
                    First of all, the Biweekly Strategy reduced the amount of
                    interest they were paying by 27.78%
                  </strong>{" "}
                  and accelerated their equity. That’s because their interest
                  was calculated each year on the new principal amount, and
                  since they’d put an entire extra mortgage payment 100% toward
                  principal, this number was lower, decreasing their interest
                  rate.
                  <br />
                  <br />
                  This makes a huge difference over time. If you had a 30-year
                  fixed loan of $250,000 at a 4% interest rate and made biweekly
                  mortgage payments, you’d save $30,000 in interest charges.
                  Even if you only stayed in your home for seven years, you’d
                  still save several thousands of dollars in interest and pay an
                  extra $10,000 toward principal. Imagine having an extra
                  $10,000 as a down payment for your next home!
                  <br />
                  <br />
                  <strong>
                    Secondly, Ross’s clients’ retirements were massively
                    improved because they were now on track to pay off their
                    homes in 22.92 years instead of 30.
                  </strong>{" "}
                  That’s SEVEN YEARS early. (Considering that the main reason
                  people continue working past retirement age is to make
                  mortgage payments… this is a big deal!)
                  <br />
                  <br />
                  <strong>Thirdly,</strong> without spending tens of thousands
                  of dollars on needless interest,{" "}
                  <strong>
                    they had more money to put in their retirement funds.
                  </strong>
                  <br />
                  <br />
                  They had more spendable income and a faster payoff, and they
                  were doing it all while keeping their same loan and lender (no
                  refinancing!).
                  <br />
                  <br />
                  <strong>
                    Fourth, they were able to drop their Private Mortgage
                    Insurance (PMI) payments much earlier.
                  </strong>{" "}
                  (Spoiler Alert: If you have an FHA Loan, you’re most likely
                  paying PMI… a lot of it!)
                  <br />
                  <br />
                  Once you’ve reached 20% equity in your home, you can ask your
                  conventional lender to cancel your PMI payments. By making
                  biweekly payments, Ross’s clients were paying more toward
                  principal, causing them to reach 20% equity much faster than
                  normal.
                  <br />
                  <br />
                  Let’s look at an example. If you have a $350,000 mortgage and
                  only put down 10% (like most people), you’d owe an extra
                  $164.06 each month to pay for PMI. If you made biweekly
                  payments, you’d hit 20% equity 13 months sooner than if you
                  were making monthly mortgage payments. That’d save you an
                  extra $2,132.78 in PMI charges.
                  <br />
                  <br />
                  <strong>
                    None of this was good news for the Big Banks…
                  </strong>{" "}
                  but Ross’s clients couldn’t have been happier!
                  <br />
                  <br />
                  After all, the only reason they’d been on a monthly payment
                  plan in the first place was because the Big Banks had tricked
                  them into it… and now that they’d seen the truth, there was no
                  going back!
                </p>
                <br />
                <div
                  style={{
                    alignItems: "center",
                    width: "100%",
                    margin: "10px",
                    textAlign: "center",
                  }}
                >
              
                  <img
                    src={IMG_4}
                    alt=""
                    style={{
                      width: "50%",
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  ></img>
                </div>

                <h1>
                  Each Month Without This Strategy Costs $438.10 In Interest
                </h1>
                <p>
                  When Ross finalized this strategy, he couldn’t wait to share
                  it with his clients! Not only was he excited for them to reap
                  the benefits of this new payment plan, but after crunching all
                  the numbers,{" "}
                  <strong>
                    he discovered how vital it is to implement this strategy
                    right away.
                  </strong>{" "}
                  Even one year could make a huge difference.
                  <br />
                  <br />
                  For example, let’s say you have a $200,000 mortgage.{" "}
                  <strong>
                    If you enrolled today, you could save six more monthly
                    payments—a total of $7,584.78—than if you waited to enroll a
                    year from now.
                  </strong>{" "}
                  You’d save $5,257.25 in interest alone during your first year
                  of enrollment!
                  <br />
                  <br />
                  And that’s just the first year. The savings will continue,
                  year after year, until your mortgage is paid off in 22.92
                  years instead of 30.{" "}
                  <strong>
                    This would eliminate 85 mortgage payments, or $132,951.05 in
                    principal and interest.
                  </strong>
                  <br />
                  <br />
                  In other words…{" "}
                  <strong>
                    each month a homeowner delays starting the Biweekly
                    Strategy, they lose $438.10 in interest savings.
                  </strong>
                </p>

                <h1>
                  Ready to See How the Biweekly Strategy Would Affect Your
                  Mortgage?
                </h1>
                <p>
                  Get started by using the calculator below to discover how much
                  money you can save—and how many years you can shave off your
                  mortgage—with the Biweekly Strategy!
                </p> */}
              </div>
              
            </div>
            

            <div style={{width:280, marginTop:75, alignSelf:'center',display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
              <a href="https://go.save7years.com/privacy" target="_blank" >Privacy Policy</a> <a href="https://go.save7years.com/tos" target="_blank">Terms of Service</a>
            </div>

            
            
            {/* <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                textAlign: "center",
                paddingTop: 50,
              }}
            >

<div className="sign-up-container-box" style={{display:'flex', flexDirection:'column', width:'90%', borderStyle:'solid', borderWidth:'2px', borderColor:'gray', marginBottom:'30px',marginTop:'0px', padding:'30px', justifyContent:'center', alignItems:'center'}}>
              <h2>Get Access to Our Mortgage Savings Plan</h2>

              <div
                className="sign-up-container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <input
                  value={this.state.name}
                  onChange={this.handleNameChange}
                  placeholder="Name"
                  style={{
                    padding: 5,
                    borderRadius: 3,
                    color: "gray",
                    width: 240,
                    height: 40,
                    marginTop: "10px",
                  }}
                ></input>
                <input
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                  placeholder="Email"
                  style={{
                    padding: 5,
                    borderRadius: 3,
                    color: "gray",
                    width: 240,
                    height: 40,
                    marginTop: "10px",
                  }}
                ></input>
                <button
                  style={{
                    padding: 5,
                    borderRadius: 3,
                    //color: "gray",
                    width: 240,
                    height: 40,
                    marginTop: "10px",
                  }}
                  onClick={() => this.sendEmail()}
                  disabled={this.state.isLoading}
                >
                  {this.state.isLoading && (
                    <i className="fa fa-spinner fa-spin" />
                  )}
                  <span style={{ marginLeft: 5 }}>Get started now</span>
                </button>
              </div>
              </div>

              {this.state.error !== "" && (
                <p
                  style={{
                    color: "red",
                  }}
                >
                  {this.state.error}
                </p>
              )}
              {this.state.success !== "" && (
                <p
                  style={{
                    color: "var(--blog-success-text)",
                  }}
                >
                  {this.state.success}
                </p>
              )}
            </div> */}
          </div>
        </Container>
      </>
    );
  }
}

export default withRouter(Blog);
